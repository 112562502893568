import { Buffer } from 'buffer';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { EmbeddedDonorAppData } from '@givz/core-client';
import {
  experienceDataSelector,
  isInModalState,
  shopifyExperienceDataState,
  showDonationModalState,
} from '../../states';

export const useFetchExperienceData = () => {
  const [, setShopifyExperienceData] = useRecoilState(
    shopifyExperienceDataState,
  );
  const [, setshowDonationModalState] = useRecoilState(showDonationModalState);
  const [, setIsInModal] = useRecoilState(isInModalState);
  const experienceData = useRecoilValue(experienceDataSelector);

  useEffect(() => {
    const parseExperienceData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const data = urlParams.get('data');
      const isInModal = urlParams.get('inModal');

      if (!data) return;
      setIsInModal(isInModal === 'true');
      let experienceData: EmbeddedDonorAppData;
      try {
        experienceData = JSON.parse(Buffer.from(data, 'base64').toString());
        setShopifyExperienceData(experienceData);
        if (experienceData.donationGid) {
          setshowDonationModalState(true);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    parseExperienceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { experienceData };
};
