import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useHistory, useParams } from 'react-router';
import { DonationCredit, DonationCreditStatus } from '@givz/core-client';
import { ClientResponse } from '@givz/core-client/dist/client/utils';
import { useCoreClient } from '../core-client';
import {
  campaignSlugState,
  donationCreditState,
  donationCreditTokenState,
  errorState,
  givzDeviceIdState,
  givzQueryParamsState,
  showDonationModalState,
} from '../../states';
import { BrowserStorage, buildUrl } from '../../utils';
import { ErrorType } from '../../states/error';
import { useGetUtmQueryParamsFromUrl } from '../app';

type CachedDonationCreditTokenType = {
  [campaignSlug: string]: string;
};

export const useGetDonationCreditTokenFromUrl = () => {
  const {
    urlCampaignSlug,
    urlDonationCreditToken,
  }: {
    urlCampaignSlug: string;
    urlDonationCreditToken: string | undefined;
  } = useParams();
  const [campaignSlug, setCampaignSlug] = useRecoilState(campaignSlugState);
  const [donationCreditToken, setDonationCreditToken] = useRecoilState(
    donationCreditTokenState,
  );

  useEffect(() => {
    /* useParams resolves missing params to a string of undefined */
    setCampaignSlug(
      urlCampaignSlug === 'undefined' ? undefined : urlCampaignSlug,
    );
    setDonationCreditToken(
      urlDonationCreditToken === 'undefined'
        ? undefined
        : urlDonationCreditToken,
    );
  }, [
    setCampaignSlug,
    setDonationCreditToken,
    urlCampaignSlug,
    urlDonationCreditToken,
  ]);

  return {
    campaignSlug,
    donationCreditToken,
  };
};

export const useFetchDonationCredit = () => {
  const client = useCoreClient();
  const routerHistory = useHistory();

  const utmQueryParams = useGetUtmQueryParamsFromUrl();
  const { campaignSlug, donationCreditToken } =
    useGetDonationCreditTokenFromUrl();
  const givzQueryParams = useRecoilValue(givzQueryParamsState);
  const [donationCredit, setDonationCredit] =
    useRecoilState(donationCreditState);
  const givzDeviceId = useRecoilValue(givzDeviceIdState);
  const [, setShowModal] = useRecoilState(showDonationModalState);
  const [, setAppError] = useRecoilState(errorState);

  useEffect(() => {
    let campaignKey: string | undefined;

    if (campaignSlug) {
      campaignKey = campaignSlug.split('-').pop();

      if (!campaignKey) {
        setAppError(ErrorType.CAMPAIGN_KEY_MISSING);
        return;
      }
    }

    const fetchDonationCredit = async () => {
      let fetchingByDonationCreditToken = true;

      try {
        let response: ClientResponse<DonationCredit> | undefined;

        if (donationCreditToken) {
          response = await client.DonationCredits.getFromToken({
            token: donationCreditToken,
          });
        }

        if (
          !donationCreditToken &&
          campaignSlug &&
          givzDeviceId &&
          !givzQueryParams?.preview
        ) {
          fetchingByDonationCreditToken = false;
          response = await client.DonationCredits.create({
            donationCreditCreate: {
              amount: givzQueryParams?.amount,
              attribution: utmQueryParams,
              hmac: givzQueryParams?.hmac ?? undefined,
              slug: campaignSlug,
            },
            givzDeviceId,
          });
        }

        if (!donationCreditToken && campaignSlug && givzQueryParams?.preview) {
          response = await client.DonationCredits.getPreviewToken({
            donationCreditCreate: {
              slug: campaignSlug,
            },
          });
        }

        if (response?.errors) {
          throw response.errors;
        }

        if (response?.data) {
          setDonationCredit(response.data);

          if (response.data.donation) {
            setShowModal(true);
          }
        }
      } catch (error: any) {
        const [firstError] = error;

        if (error.length > 0 && firstError?.code === 404) {
          setAppError(
            fetchingByDonationCreditToken
              ? ErrorType.DONATION_CREDIT_NOT_FOUND
              : ErrorType.CAMPAIGN_NOT_FOUND,
          );
        }

        if (error.length > 0 && firstError?.code === 422) {
          const { message } = firstError;
          if (message.includes('token creation policy is not public')) {
            setAppError(ErrorType.CAMPAIGN_NOT_PUBLIC);
          } else if (message.includes('Missing campaign key')) {
            setAppError(ErrorType.CAMPAIGN_KEY_MISSING);
          } else if (message.includes('campaign type is not hosted')) {
            setAppError(ErrorType.NON_HOSTED_CAMPAIGN_MISSING_DONATION_CREDIT);
          } else {
            setAppError(ErrorType.CAMPAIGN_EXPIRED_OR_INVALID);
          }
        }

        Sentry.captureException(error);
      }
    };

    if (
      campaignSlug &&
      givzQueryParams &&
      !givzQueryParams.preview &&
      !givzQueryParams.reset
    ) {
      const cachedDonationCreditTokens = BrowserStorage.get(
        'givz-donation-credit-token',
      );
      let parsedDonationCreditTokens: CachedDonationCreditTokenType = {};

      try {
        parsedDonationCreditTokens = cachedDonationCreditTokens
          ? JSON.parse(cachedDonationCreditTokens)
          : {};
      } catch (error) {
        //
      }

      const parsedDonationCreditToken = campaignKey
        ? parsedDonationCreditTokens[campaignKey]
        : undefined;

      if (
        !donationCreditToken &&
        !donationCredit &&
        parsedDonationCreditToken
      ) {
        routerHistory.push(
          buildUrl(
            `/c/${campaignSlug}/${parsedDonationCreditToken}`,
            utmQueryParams,
          ),
        );
        return;
      }

      if (donationCreditToken && campaignKey) {
        parsedDonationCreditTokens[campaignKey] = donationCreditToken;

        BrowserStorage.set(
          'givz-donation-credit-token',
          JSON.stringify(parsedDonationCreditTokens),
        );
      }
    }

    fetchDonationCredit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignSlug, donationCreditToken, givzDeviceId, givzQueryParams]);

  useEffect(() => {
    if (donationCredit?.status === DonationCreditStatus.Expired) {
      setAppError(ErrorType.DONATION_CREDIT_EXPIRED);
    }
  }, [donationCredit, setAppError]);

  useEffect(() => {
    if (!donationCreditToken && donationCredit && !givzQueryParams?.preview) {
      routerHistory.push(
        buildUrl(`/c/${campaignSlug}/${donationCredit.token}`, utmQueryParams),
      );
    }

    if (givzQueryParams?.reset) {
      BrowserStorage.remove('givz-donation-credit-token');
    }
  }, [
    campaignSlug,
    donationCredit,
    donationCreditToken,
    givzQueryParams,
    routerHistory,
    utmQueryParams,
  ]);

  return {
    donationCredit,
  };
};
