import { ValidationError } from '@givz/core-client';
import Cookies, { CookieAttributes } from 'js-cookie';

export type DeepNullablePartial<T> = {
  [P in keyof T]?: DeepNullablePartial<T[P]> | null;
};

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export const buildUrl = (path: string, params?: Record<string, string>) => {
  let builtUrl = path;

  if (params) {
    builtUrl = `${builtUrl}?${new URLSearchParams(params).toString()}`;
  }
  return builtUrl;
};

export const errorsToString = (errors: ValidationError[]) => {
  // sort so errors without fields are first
  errors.sort((a, b) => {
    if ('field' in a === 'field' in b) return 0;
    else if (!a.field) return -1;
    return 1;
  });
  return errors.map((error) => error.message).join('; ');
};

export const getRandomElementFromArray = (array: unknown[]) =>
  array[Math.floor(Math.random() * array.length)];

const isNull = (value: any) => value === null;
const isUndefined = (value: any) => value === undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeObjects =
  (
    config: {
      excludeNull: boolean;
      excludeUndefined: boolean;
    } = { excludeNull: true, excludeUndefined: true },
  ) =>
  <T>(...sources: any[]): T => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { excludeNull, excludeUndefined } = config;
    const isObject = (value: any): boolean => {
      return value && value.constructor === Object;
    };

    const merged: Record<string, any> = {};

    for (const source of sources) {
      for (const key in source) {
        // no previous values will be overwritten by null / undefined
        if (
          (excludeNull && isNull(source[key])) ||
          (excludeUndefined && isUndefined(source[key]))
        ) {
          continue;
        }

        // TODO: figure out if we care about arrays. lodash's merge will
        // iterate through arrays and merge merged[key][0] with source[key][0].
        // Right now, our objects don't have that kind of structure so
        // overwriting the "organizationNames" array for example with whatever
        // comes in from the URL params is desired behavior

        // if previous and new values are both objects, recurse
        if (isObject(merged[key]) && isObject(source[key])) {
          merged[key] = mergeObjects(config)(merged[key], source[key]);
        } else {
          merged[key] = source[key];
        }
      }
    }
    return merged as T;
  };

export const plusYear = (years: number) => {
  const date = new Date();
  date.setFullYear(new Date().getFullYear() + years);
  return date;
};

const getSafety =
  (getFn: (...args: any[]) => any) =>
  (...args: any[]) => {
    let value;
    try {
      value = getFn(...args);
    } catch (error) {
      console.warn((error as Error).message);
    }
    return value;
  };

const setSafety =
  (setFn: (...args: any[]) => any) =>
  (...args: any[]) => {
    try {
      setFn(...args);
    } catch (error) {
      console.warn((error as Error).message);
    }
  };

export class BrowserStorage {
  static get(key: string) {
    return BrowserStorage.getCookie(key) || BrowserStorage.getLocalStorage(key);
  }

  static remove(key: string) {
    BrowserStorage.removeCookie(key);
    BrowserStorage.removeLocalStorage(key);
  }

  static set(key: string, value: string, options?: CookieAttributes) {
    BrowserStorage.setCookie(key, value, options);
    BrowserStorage.setLocalStorage(key, value);
  }

  static removeCookie(key: string) {
    setSafety(Cookies.remove)(key);
  }

  static removeLocalStorage(key: string) {
    const remove = (k: string) => localStorage.removeItem(k);
    setSafety(remove)(key);
  }

  static setCookie(key: string, value: string, options?: CookieAttributes) {
    setSafety(Cookies.set)(key, value, options);
  }

  static setLocalStorage(key: string, value: string) {
    const set = (k: string, v: string) => localStorage.setItem(k, v);
    setSafety(set)(key, value);
  }

  static getCookie(key: string): string | undefined {
    return getSafety(Cookies.get)(key);
  }

  static getLocalStorage(key: string): string | undefined {
    const get = (k: string) => localStorage.getItem(k);
    return getSafety(get)(key);
  }
}

export const openUrlInNewTab = (url: string) => {
  window.open(url, '_blank');
};

export const truthyStringToBoolean = (value: string | null): boolean => {
  return value === '1' || value === 'true';
};
