import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ConsumerUnsubscribe } from './components/consumer-unsubscribe';
import { Embedded } from './components/embedded';
import { HostedCampaign } from './components/hosted-campaign';
import { useTheme } from './hooks/theme';

const App = () => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/unsubscribe">
            <ConsumerUnsubscribe />
          </Route>
          <Route path="/embedded">
            <Embedded />
          </Route>
          <Route path="/c/:urlCampaignSlug/:urlDonationCreditToken">
            <HostedCampaign />
          </Route>
          <Route path="/c/:urlCampaignSlug">
            <HostedCampaign />
          </Route>
          <Route exact path="/">
            <Redirect from="/" to={{ ...location, pathname: '/embedded' }} />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
