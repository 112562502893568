import { createRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Organization } from '@givz/core-client';
import { OrganizationImage } from '../organization-image';
import { openUrlInNewTab } from '../../utils';
import {
  DonateButton,
  DonateButtonContainer,
  FlexboxContainer,
  OrganizationMetadata,
  OrganizationMetadataContainer,
} from '../common';
import paginationIcon from './arrow.svg';
import OrganizationBadge from './badge';

type FeaturedOrganizationsProps = {
  donationCreditIsUnclaimed?: boolean;
  featuredOrganizations: Array<Organization>;
  toggleModal: (organization: Organization | undefined) => void;
  parentContainerRef: React.RefObject<HTMLDivElement>;
};

const Container = styled.div<{ active: boolean; width: number }>`
  cursor: ${(props) =>
    props.active ? 'grabbing !important' : 'grab !important'};
  height: ${(props) => `${props.width * (224 / 460)}px`};
  max-height: 224px;
  position: relative;
  user-select: none;
  width: 100%;
`;

const FeaturedOrganization = styled.div`
  cursor: pointer;
  height: 100%;
  margin: 0 25px 0 0;
  min-width: 300px;
  position: relative;
  width: 300px;
  @media (max-width: 460px) {
    width: calc(65% + 25px);
    min-width: calc(65% + 25px);
  }
`;

const FeaturedOrganizationsContainer = styled(FlexboxContainer)<{
  active: boolean;
}>`
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  ${FeaturedOrganization}:last-child {
    margin: 0;
  }
  ${(props) =>
    props.active &&
    css`
      > * {
        cursor: grabbing !important;
      }
    `}
`;

const FeaturedOrganizationImageContainer = styled.div<{
  width: number;
  hasBadge: boolean;
}>`
  border: solid 1px #e2e2e2;
  border-radius: 11px;
  height: calc(100% - 54px);
  position: relative;
  width: 100%;
`;

const OrganizationDonateButtonContainer = styled.div`
  ${DonateButtonContainer} {
    position: absolute;
    right: 14px;
    top: 14px;
    &:hover {
      top: 13px;
    }
  }
`;

const FeaturedOrganizationTitle = styled.div`
  color: #000000;
  cursor: pointer;
  font-family: 'Roboto-Bold', sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24px;
  margin: 10px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FeaturedOrganizationMetadataContainer = styled.div`
  ${OrganizationMetadataContainer} {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const PaginationRightImageContainer = styled.div<{
  show: boolean;
  width: number;
}>`
  cursor: pointer;
  display: ${(props) => (props.show ? 'block' : 'none')};
  height: 50px;
  position: absolute;
  right: -25px;
  top: calc((100% - 54px - 50px) / 2);
  width: 50px;
  z-index: 2;
  &:hover {
    top: calc((100% - 54px - 50px) / 2 + 1);
  }
`;

const PaginationRightImage = styled.img`
  height: 100%;
  position: relative;
  width: 100%;
`;

const PaginationLeftImageContainer = styled.div<{
  show: boolean;
  width: number;
}>`
  cursor: pointer;
  display: ${(props) => (props.show ? 'block' : 'none')};
  height: 50px;
  left: -25px;
  position: absolute;
  top: calc((100% - 54px - 50px) / 2);
  transform: scaleX(-1);
  width: 50px;
  z-index: 2;
  &:hover {
    top: calc((100% - 54px - 50px) / 2 + 1);
  }
`;

const PaginationLeftImage = styled.img`
  height: 100%;
  position: relative;
  width: 100%;
`;

export const FeaturedOrganizations = (props: FeaturedOrganizationsProps) => {
  const {
    donationCreditIsUnclaimed,
    featuredOrganizations,
    parentContainerRef,
    toggleModal,
  } = props;
  const scrollerTarget = createRef<HTMLDivElement>();
  const [mouseConfig, setMouseConfig] = useState<
    Record<string, number | boolean>
  >({});
  const [pageWidth, setPageWidth] = useState(0);
  const [showScroll, setShowScroll] = useState([false, true]);

  const scrollFeaturedOrganizations = (direction: string) => {
    if (!scrollerTarget.current || !parentContainerRef.current) return;
    let { scrollLeft } = scrollerTarget.current;
    const { scrollWidth } = scrollerTarget.current;
    const width = parentContainerRef.current.clientWidth - 40;
    const cardWidth = Math.floor(
      (scrollWidth + 25) / featuredOrganizations.length,
    );
    if (direction === 'left' && scrollLeft === 0) return;
    if (direction === 'right' && scrollLeft + width >= scrollWidth) return;
    if (direction === 'left') {
      let extra = 0;
      if (Number.isInteger(scrollLeft / cardWidth)) extra = -1; // right on edge of card
      scrollLeft = cardWidth * (Math.floor(scrollLeft / cardWidth) + extra);
    } else {
      // the 0.01 is to handle a Chrome bug when the browser is zoomed in,
      // shrinking the card width by a few fractional pixels
      scrollLeft = cardWidth * (Math.floor(scrollLeft / cardWidth + 0.01) + 1);
    }
    scrollerTarget.current.scrollLeft = scrollLeft;
    changeScrollButtonState();
  };

  const changeScrollButtonState = (): void => {
    if (!scrollerTarget.current || !parentContainerRef.current) return;
    const newState: [boolean, boolean] = [false, false],
      { scrollLeft } = scrollerTarget.current,
      { scrollWidth } = scrollerTarget.current,
      width = parentContainerRef.current.clientWidth - 40;
    if (scrollLeft > 0) newState[0] = true;
    // the 20 is to handle a Chrome bug when the browser is zoomed in,
    // shrinking the card width by a few fractional pixels
    if (scrollLeft + width < scrollWidth - 20) newState[1] = true;
    setShowScroll(newState);
    setPageWidth(parentContainerRef.current.clientWidth);
  };

  const mouseDownHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (!scrollerTarget.current) return;
    setMouseConfig({
      ...mouseConfig,
      active: true,
      left: scrollerTarget.current.scrollLeft,
      x: e.clientX,
    });
  };

  const mouseMoveHandler = (e: MouseEvent) => {
    if (!scrollerTarget.current || !mouseConfig.active) return;
    const dx = e.clientX - (mouseConfig.x as number);
    scrollerTarget.current.scrollLeft = (mouseConfig.left as number) - dx;
    changeScrollButtonState();
  };

  const mouseUpHandler = () => {
    if (!scrollerTarget.current || !mouseConfig.active) return;
    if (mouseConfig.left === scrollerTarget.current.scrollLeft) {
      setMouseConfig({
        ...mouseConfig,
        active: false,
      });
    } else {
      setTimeout(() => {
        setMouseConfig({
          ...mouseConfig,
          active: false,
        });
      }, 10);
    }
  };

  const onClickFeaturedOrganizationResult = (charity: Organization) => {
    if (mouseConfig.active) return;

    if (donationCreditIsUnclaimed) {
      toggleModal(charity);
    } else {
      openUrlInNewTab(charity.websiteUrl ?? charity.paypalUrl);
    }
  };

  const touchStartHandler = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!scrollerTarget.current) return;
    setMouseConfig({
      ...mouseConfig,
      active: true,
      left: scrollerTarget.current.scrollLeft,
      x: e.touches[0].clientX,
    });
  };

  const touchMoveHandler = (e: TouchEvent) => {
    if (!scrollerTarget.current || !mouseConfig.active) return;
    const dx = e.touches[0].clientX - (mouseConfig.x as number);
    scrollerTarget.current.scrollLeft = (mouseConfig.left as number) - dx;
    changeScrollButtonState();
  };

  useEffect(() => {
    window.addEventListener('resize', changeScrollButtonState);
    window.addEventListener('mousemove', mouseMoveHandler);
    window.addEventListener('mouseup', mouseUpHandler);
    window.addEventListener('touchmove', touchMoveHandler);
    window.addEventListener('touchend', mouseUpHandler);
    return () => {
      window.removeEventListener('resize', changeScrollButtonState);
      window.removeEventListener('mousemove', mouseMoveHandler);
      window.removeEventListener('mouseup', mouseUpHandler);
      window.removeEventListener('touchmove', touchMoveHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollerTarget]);

  useEffect(() => {
    changeScrollButtonState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuredOrganizations]);

  return (
    <Container active={mouseConfig.active as boolean} width={pageWidth}>
      <FeaturedOrganizationsContainer
        active={mouseConfig.active as boolean}
        onMouseDown={(e) => mouseDownHandler(e)}
        onTouchStart={(e) => touchStartHandler(e)}
        ref={scrollerTarget}
      >
        {featuredOrganizations.map((charity) => {
          const { gid, logoUrl, name, tags } = charity;
          const hasTags = Boolean(tags && tags.length > 0);

          return (
            <FeaturedOrganization
              key={gid}
              onClick={() => onClickFeaturedOrganizationResult(charity)}
            >
              <FeaturedOrganizationImageContainer
                hasBadge={hasTags}
                width={pageWidth}
              >
                {hasTags && tags && <OrganizationBadge tag={tags[0]} />}

                <OrganizationImage
                  height={'100%'}
                  width={'100%'}
                  src={logoUrl || ''}
                />

                <OrganizationDonateButtonContainer>
                  <DonateButton organizationTags={tags}>
                    {donationCreditIsUnclaimed ? 'Donate' : 'Visit'}
                  </DonateButton>
                </OrganizationDonateButtonContainer>
              </FeaturedOrganizationImageContainer>
              <FeaturedOrganizationTitle>{name}</FeaturedOrganizationTitle>
              <FeaturedOrganizationMetadataContainer>
                <OrganizationMetadata organization={charity} />
              </FeaturedOrganizationMetadataContainer>
            </FeaturedOrganization>
          );
        })}
      </FeaturedOrganizationsContainer>
      <PaginationLeftImageContainer
        onClick={() => scrollFeaturedOrganizations('left')}
        show={showScroll[0]}
        width={pageWidth}
      >
        <PaginationLeftImage src={paginationIcon}></PaginationLeftImage>
      </PaginationLeftImageContainer>
      <PaginationRightImageContainer
        onClick={() => scrollFeaturedOrganizations('right')}
        show={showScroll[1]}
        width={pageWidth}
      >
        <PaginationRightImage src={paginationIcon}></PaginationRightImage>
      </PaginationRightImageContainer>
    </Container>
  );
};
