import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ErrorType } from '../../states/error';
import { openUrlInNewTab } from '../../utils';
import { CenteringContainer, HeaderText } from '../common/layout';
import givzLogo from '../header/givz-logo.svg';

const GivzLogo = styled.img`
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const ErrorMessage = styled(HeaderText)`
  margin: 20px 0 0;
  text-align: center;
  width: auto;
`;

const SubErrorMessage = styled.div`
  font-size: 22px;
  margin: 10px 0 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Error: FunctionComponent<{
  appError?: ErrorType;
  appErrorMessage: string;
}> = ({ appError, appErrorMessage }) => {
  return (
    <CenteringContainer>
      <GivzLogo
        onClick={() =>
          openUrlInNewTab('https://givz.com?utm_source=hosted_campaign')
        }
        src={givzLogo}
      ></GivzLogo>

      <ErrorMessage>{appErrorMessage}</ErrorMessage>

      {appError !== ErrorType.CAMPAIGN_EXPIRED_OR_INVALID && (
        <SubErrorMessage>
          Please check your URL and contact the business that sent it to you for
          assistance.
        </SubErrorMessage>
      )}
    </CenteringContainer>
  );
};

export default Error;
