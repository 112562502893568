import { FunctionComponent, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { environment, GivzEnvType } from '../../config';
import { isInModalState, isInPreviewModeSelector } from '../../states';
import { openUrlInNewTab } from '../../utils';
import { PreviewModeBanner } from '../common';
import poweredByGivzLogo from './powered-by-givz-logo.svg';

const HeaderContainer = styled.div<{
  bottomBorder?: boolean;
  isInModal: boolean;
}>`
  border-bottom: ${(props) =>
    props.bottomBorder ? '1px solid #000' : undefined};
  position: ${(props) => (props.isInModal ? 'fixed' : 'undefined')};
  width: 100%;
`;

const BannerContainer = styled.div<{
  environment: GivzEnvType;
}>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary.hex};
  box-sizing: border-box;
  display: flex;
  height: 65px;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
`;

const GivzLogoContainer = styled.div`
  height: 24px;
  position: relative;
  width: 125px;
`;

const GivzLogo = styled.img`
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const EnvironmentButton = styled.div`
  align-items: center;
  border: 1px solid white;
  border-radius: 6px;
  color: white;
  display: flex;
  font-family: 'Roboto-Bold', sans-serif;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  padding: 5px 10px;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const DonationAmount = styled.div`
  color: white;
  font-family: 'Roboto-Bold', sans-serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0px;
`;
export type HeaderProps = {
  amount?: string;
  bottomBorder?: boolean;
  isInHostedMode?: boolean;
};
export const Header: FunctionComponent<HeaderProps> = ({
  amount,
  bottomBorder,
  isInHostedMode,
}) => {
  const isInModal = useRecoilValue(isInModalState);
  const isInPreviewMode = useRecoilValue(isInPreviewModeSelector);
  const [showEnvironmentButton, setShowEnvironmentButton] = useState(true);
  return (
    <HeaderContainer bottomBorder={bottomBorder} isInModal={isInModal}>
      <BannerContainer environment={environment.app.env}>
        <GivzLogoContainer>
          <GivzLogo
            onClick={() =>
              openUrlInNewTab('https://givz.com?utm_source=hosted_campaign')
            }
            src={poweredByGivzLogo}
          ></GivzLogo>
        </GivzLogoContainer>
        {environment.app.env !== 'production' && showEnvironmentButton && (
          <EnvironmentButton onClick={() => setShowEnvironmentButton(false)}>
            {environment.app.env}
          </EnvironmentButton>
        )}
        <DonationAmount>{amount}</DonationAmount>
      </BannerContainer>
      {isInPreviewMode && !isInHostedMode && <PreviewModeBanner />}
    </HeaderContainer>
  );
};
