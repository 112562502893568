import { atom, selector } from 'recoil';

export enum ErrorType {
  CAMPAIGN_NOT_FOUND = 'CAMPAIGN_NOT_FOUND',
  CAMPAIGN_EXPIRED_OR_INVALID = 'CAMPAIGN_EXPIRED_OR_INVALID',
  CAMPAIGN_NOT_PUBLIC = 'CAMPAIGN_NOT_PUBLIC',
  CAMPAIGN_KEY_MISSING = 'CAMPAIGN_KEY_MISSING',
  CONSUMER_UNSUBSCRIBE_TOKEN_INVALID = 'CONSUMER_UNSUBSCRIBE_TOKEN_INVALID',
  CONSUMER_UNSUBSCRIBE_TOKEN_MISSING = 'CONSUMER_UNSUBSCRIBE_TOKEN_MISSING',
  DONATION_CREDIT_EXPIRED = 'DONATION_CREDIT_EXPIRED',
  DONATION_CREDIT_NOT_FOUND = 'DONATION_CREDIT_NOT_FOUND',
  NON_HOSTED_CAMPAIGN_MISSING_DONATION_CREDIT = 'NON_HOSTED_CAMPAIGN_MISSING_DONATION_CREDIT',
}

export const errorState = atom<ErrorType | undefined>({
  default: undefined,
  key: 'errorState',
});

export const errorMessageSelector = selector<string | undefined>({
  get: ({ get }) => {
    const error = get(errorState);

    switch (error) {
      case ErrorType.CAMPAIGN_NOT_FOUND:
        return 'Incentive not found.';
      case ErrorType.CAMPAIGN_NOT_PUBLIC:
      case ErrorType.CAMPAIGN_KEY_MISSING:
      case ErrorType.NON_HOSTED_CAMPAIGN_MISSING_DONATION_CREDIT:
        return 'Part of your donation URL is missing.';
      case ErrorType.CONSUMER_UNSUBSCRIBE_TOKEN_INVALID:
        return 'Your unsubscribe token is invalid';
      case ErrorType.CONSUMER_UNSUBSCRIBE_TOKEN_MISSING:
        return 'Your unsubscribe token is missing';
      case ErrorType.DONATION_CREDIT_NOT_FOUND:
        return 'Part of your donation URL is incorrect.';
      case ErrorType.CAMPAIGN_EXPIRED_OR_INVALID:
        return 'This incentive has expired.';
      case ErrorType.DONATION_CREDIT_EXPIRED:
        return 'Your donation credit has expired.';
    }
  },
  key: 'errorMessageSelector',
});
