import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Organization } from '@givz/core-client';
import { useRecoilState } from 'recoil';
import {
  DonateButton,
  Input,
  LoadingKeyframes,
  OrganizationMetadata,
  SvgContainer,
} from '../common';
import { useCoreClient } from '../../hooks';
import { organizationsSearchState } from '../../states';
import backIcon from './back.png';
import searchIcon from './search.svg';

type FeaturedOrganizationsProps = {
  featuredOrganizations: Organization[];
  onBlur: () => void;
  onFocus: () => void;
  searchFocused: boolean;
  toggleModal: (charity: Organization) => void;
};

const Container = styled.div<{ isFocused: boolean }>`
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(226, 226, 226);
  border-radius: 4px;
  box-sizing: border-box;
  color: rgb(52, 52, 52);
  width: 100%;
  ${(props) =>
    props.isFocused &&
    css`
      border: none;
      border-radius: 0px;
    `}
`;

const SearchContainer = styled.div<{ isFocused: boolean }>`
  align-items: center;
  border-bottom: ${(props) =>
    props.isFocused && '1px solid rgb(226, 226, 226)'};
  display: flex;
  height: 50px;
  padding: ${(props) => (props.isFocused ? '0' : '0 15px')};
`;

const SearchIconContainer = styled.div<{ isFocused: boolean }>`
  height: 22px;
  margin: 0 18px 0 0;
  width: 22px;
  ${(props) =>
    props.isFocused &&
    css`
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    `}
`;

const SearchBoxContainer = styled.div`
  width: 100%;
`;

const OrganizationLoading = styled.div`
  background-color: white;
  border-bottom: 1px solid rgb(226, 226, 226);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  width: 100%;
  &:after {
    animation: ${LoadingKeyframes} 1.2s linear infinite;
    border: 2px solid black;
    border-color: black transparent black transparent;
    border-radius: 50%;
    content: ' ';
    display: block;
    height: 20px;
    margin: 0 auto;
    width: 20px;
  }
`;

const OrganizationResult = styled.div`
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
`;

const OrganizationResults = styled.div`
  background-color: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  height: 100%;
  margin: 1px 0 0;
  width: 100%;
  z-index: 9;
  ${OrganizationResult}:last-child {
    border-bottom: none;
  }
`;

const FeaturedOrganizationTitle = styled.div`
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  color: #000000;
  display: flex;
  font-family: 'Roboto-Bold', sans-serif;
  font-size: 14px;
  font-weight: 700;
  height: 20px;
  justify-content: space-between;
  letter-spacing: 0px;
  line-height: 24px;
  padding: 15px 0;
`;

const OrganizationResultsScrollContainer = styled.div<{
  organizationResults: boolean;
}>`
  height: calc(100% - 105px);
  overflow-y: auto;
  ${(props) =>
    props.organizationResults &&
    css`
      height: calc(100% - 58px);
    `}
`;

const OrganizationResultDecsription = styled.div`
  cursor: pointer;
  margin: 0 20px 0 0;

  @media (max-width: 500px) {
    margin: 0;
    width: 70%;
  }
`;

const OrganizationResultDecsriptionTitle = styled.div`
  color: #000000;
  font-family: 'Roboto-Bold', sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const OrganizationResultDecsriptionMetadata = styled.div`
  color: #000000;
  font-family: 'Roboto-Regular', sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const OrganizationsResultsComponent = (props: {
  isFeaturedOrganizations?: boolean;
  organizations: Organization[];
  toggleModal(organization: Organization): void;
}) => {
  const { isFeaturedOrganizations, organizations, toggleModal } = props;

  return (
    <OrganizationResults>
      {isFeaturedOrganizations && (
        <FeaturedOrganizationTitle>
          Featured Organizations
        </FeaturedOrganizationTitle>
      )}
      <OrganizationResultsScrollContainer organizationResults={false}>
        {organizations.map((organization) => {
          const { name, tags } = organization;

          return (
            <OrganizationResult key={name}>
              <OrganizationResultDecsription
                onClick={() => toggleModal(organization)}
              >
                <OrganizationResultDecsriptionTitle>
                  {name}
                </OrganizationResultDecsriptionTitle>
                <OrganizationResultDecsriptionMetadata>
                  <OrganizationMetadata organization={organization} />
                </OrganizationResultDecsriptionMetadata>
              </OrganizationResultDecsription>
              <DonateButton
                organizationTags={tags}
                onClick={() => toggleModal(organization)}
              >
                Donate
              </DonateButton>
            </OrganizationResult>
          );
        })}
      </OrganizationResultsScrollContainer>
    </OrganizationResults>
  );
};

export const OrganizationSearchBox = (props: FeaturedOrganizationsProps) => {
  const { featuredOrganizations, onBlur, onFocus, searchFocused, toggleModal } =
    props;
  const client = useCoreClient();
  const [charities, setOrganizations] = useState<Organization[]>([]);
  const [currentSearchPage, setCurrentSearchPage] = useState(1);
  const [isFocused, setIsFocused] = useState(searchFocused);
  const [isLoading, setIsLoading] = useState(false);
  const [lastSearchText, setLastSearchText] = useState('');
  const [searchText, setSearchText] = useRecoilState(organizationsSearchState);

  const handleSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();

    if (value !== searchText) {
      setSearchText(value);
    }
  };

  const onChildFocus = () => {
    setIsFocused(true);
    onFocus();
  };

  const onChildBlur = () => {
    setIsFocused(false);
    onBlur();
  };

  const fetchSearchResult = async (search: string, page: number) => {
    if (search.trim() === lastSearchText) return;
    if (search === '') {
      setLastSearchText('');
      setOrganizations([]);
      setCurrentSearchPage(1);
      setIsLoading(false);
      return;
    }
    setLastSearchText(search.trim());
    setIsLoading(true);
    const { data, errors } = await client.Organizations.search({
      page,
      pageSize: 10,
      q: search,
    });
    setIsLoading(false);
    if (errors) {
      setOrganizations([]);
      setCurrentSearchPage(1);
      setIsLoading(false);
      Sentry.captureException(errors);
    } else {
      setOrganizations(data.organizations);
      setCurrentSearchPage(data.meta.currentPage);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchSearchResult(searchText, currentSearchPage);
    }, 200);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSearchText, searchText]);

  useEffect(() => {
    setIsFocused(searchFocused);
  }, [searchFocused]);

  return (
    <Container isFocused={isFocused}>
      <SearchContainer isFocused={isFocused}>
        <SearchIconContainer isFocused={isFocused}>
          <SvgContainer
            onClick={() => (isFocused ? onChildBlur() : '')}
            src={isFocused ? backIcon : searchIcon}
          />
        </SearchIconContainer>
        <SearchBoxContainer>
          <Input
            placeholder="Search charities by name or EIN"
            value={searchText}
            onChange={handleSearchOnChange}
            onFocus={onChildFocus}
          />
        </SearchBoxContainer>
      </SearchContainer>
      {isLoading && isFocused && <OrganizationLoading />}

      {charities.length === 0 &&
        featuredOrganizations.length > 0 &&
        !isLoading &&
        isFocused && (
          <OrganizationsResultsComponent
            isFeaturedOrganizations={true}
            organizations={featuredOrganizations}
            toggleModal={toggleModal}
          />
        )}
      {charities.length > 0 && !isLoading && isFocused && (
        <OrganizationsResultsComponent
          organizations={charities}
          toggleModal={toggleModal}
        />
      )}
    </Container>
  );
};
