import { atom } from 'recoil';
import { DonationCredit } from '@givz/core-client';

export const donationCreditTokenState = atom<string | undefined>({
  default: undefined,
  key: 'donationCreditTokenState',
});

export const donationCreditState = atom<DonationCredit | undefined>({
  default: undefined,
  key: 'donationCreditState',
});
