import React, { FunctionComponent, MouseEventHandler } from 'react';
import styled from 'styled-components';

export interface ModalProps {
  children?: React.ReactNode;
  transparentBackground?: boolean;
  onClose?(): void;
  open: boolean;
}

export const PageContainer = styled.div<{
  transparentBackground?: boolean;
}>`
  background-color: ${(props) =>
    props.transparentBackground ? 'transparent' : 'rgba(0, 0, 0, 0.4)'};
  height: 100%;
  left: 0;
  overflow-y: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2147483647;
`;

const ModalContainer = styled.div`
  align-items: center;

  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow-y: hidden;
  width: 100%;

  @media (max-width: 768px) {
    width: 92%;
    margin: 0 auto;
  }
`;

const OuterContentContainer = styled.div<{
  transparentBackground?: boolean;
}>`
  background-color: #fff;
  border: ${(props) =>
    props.transparentBackground ? '1px solid #e2e2e2' : undefined};
  border-radius: 5px;
`;

const InnerContentContainer = styled.div`
  padding: 2.5rem 1.75rem;
`;

const CloseButtonContainer = styled.div`
  background-color: #fff;
  border-radius: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  width: 100%;

  &:hover {
    color: rgb(0, 0, 0, 0.6);
  }
`;

const CloseButton = styled.div`
  font-size: 16px;
  padding: 0.25rem;
  text-transform: uppercase;
`;

const Modal: FunctionComponent<ModalProps> = (props) => {
  const { children, onClose, transparentBackground } = props;

  const stopPropagation: MouseEventHandler<HTMLDivElement> = (event) =>
    event.stopPropagation();

  return (
    <PageContainer
      onClick={onClose}
      transparentBackground={transparentBackground}
    >
      <ModalContainer>
        <OuterContentContainer
          onClick={stopPropagation}
          transparentBackground={transparentBackground}
        >
          <InnerContentContainer>{children}</InnerContentContainer>
          <CloseButtonContainer onClick={onClose}>
            <CloseButton>Dismiss</CloseButton>
          </CloseButtonContainer>
        </OuterContentContainer>
      </ModalContainer>
    </PageContainer>
  );
};

export default Modal;
