import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import {
  useFetchExperienceData,
  useGetGivzQueryParamsFromUrl,
  useTrackDevice,
} from '../../hooks';
import { isEmbeddedState, isInModalState } from '../../states';
import { openUrlInNewTab } from '../../utils';
import { ActionButton } from '../common';
import { DonationForm } from '../donation-form';
import { Header } from '../header';

const Container = styled.div<{
  smallHeight?: boolean;
}>`
  display: flex;
  justify-content: center;
  min-height: ${(props) => (props.smallHeight ? undefined : '400px')};
  overflow-x: hidden;
  overflow-y: auto;
`;

const ContentContainer = styled.div`
  border-radius: 5px;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  text-align: left;
  width: 100%;
`;

const VisitHostedDonationPageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
`;

const VisitHostedDonationPageText = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

export const Embedded = () => {
  useGetGivzQueryParamsFromUrl();
  useTrackDevice();
  const [, setIsEmbedded] = useRecoilState(isEmbeddedState);
  const isInModal = useRecoilValue(isInModalState);
  const { experienceData } = useFetchExperienceData();
  const [scrollHeight, setScrollHeight] = useState(0);

  const checkForHeightChange = () => {
    const newScrollHeight = document.body.scrollHeight;
    if (newScrollHeight !== scrollHeight) {
      setScrollHeight(newScrollHeight);
      window.parent.postMessage({ newScrollHeight }, '*');
    }
  };

  useEffect(() => {
    const heightCheck = setInterval(() => {
      checkForHeightChange();
    }, 200);
    return () => {
      clearInterval(heightCheck);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollHeight]);

  useEffect(() => {
    setIsEmbedded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInModal) {
      document.body.style.overflow = '';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [isInModal]);

  const handleOpenHostedInNewTab = () =>
    openUrlInNewTab(
      `/c/${experienceData?.campaignSlug}/${experienceData?.donationCreditToken}`,
    );

  return (
    <Container smallHeight={experienceData?.useHostedDonationPage}>
      <ContentContainer>
        <Header amount={experienceData?.formattedDonationCreditAmount}></Header>
        {!experienceData?.useHostedDonationPage && <DonationForm />}
        {experienceData?.useHostedDonationPage && (
          <VisitHostedDonationPageContainer>
            {experienceData.donationGid && (
              <VisitHostedDonationPageText>
                You made a {experienceData.formattedDonationCreditAmount}{' '}
                donation!
              </VisitHostedDonationPageText>
            )}
            {!experienceData.donationGid && (
              <VisitHostedDonationPageText>
                You have a {experienceData.formattedDonationCreditAmount}{' '}
                donation available!
              </VisitHostedDonationPageText>
            )}
            <ActionButton onClick={handleOpenHostedInNewTab}>
              {experienceData.donationGid ? 'View' : 'Complete'} Your Donation
            </ActionButton>
          </VisitHostedDonationPageContainer>
        )}
      </ContentContainer>
    </Container>
  );
};
