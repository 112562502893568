import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { errorState } from '../../states';
import { ErrorType } from '../../states/error';
import { useCoreClient } from '../core-client';

export const useUnsubscribeConsumer = () => {
  const client = useCoreClient();
  const [, setAppError] = useRecoilState(errorState);

  const unsubscribeConsumer = async (token: string) => {
    const { errors } = await client.Consumer.unsubscribe({
      unsubscribe: {
        token,
      },
    });

    if (errors) {
      setAppError(ErrorType.CONSUMER_UNSUBSCRIBE_TOKEN_INVALID);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    if (token) {
      unsubscribeConsumer(token);
    } else {
      setAppError(ErrorType.CONSUMER_UNSUBSCRIBE_TOKEN_MISSING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);
};
