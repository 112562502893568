import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    gzPrimary: {
      hex: '#37746b',
      rgb: '22, 45, 42, 1',
    },
    primary: {
      hex: '#37746b',
      rgb: '22, 45, 42, 1',
    },
    ukraineBlue: {
      hex: '#4c8cec',
      rgb: '76, 140, 236, 1',
    },
  },
};

export default theme;
