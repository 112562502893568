import { TextElement, TextElementCss } from '@givz/core-client';
import { DeepNullablePartial, truthyStringToBoolean } from '.';

const nullValue = 'null';
const gzPreviewKey = 'gz.preview';

export function isGivzPreview(params: URLSearchParams): boolean {
  return truthyStringToBoolean(params.get(gzPreviewKey));
}

export function getGivzCssQueryParams(
  params: URLSearchParams,
  prefix: string,
): DeepNullablePartial<TextElementCss> {
  return {
    font: getGivzQueryParam({
      key: `${prefix}.css.font`,
      params,
    }),
    margin: getGivzQueryParam({
      key: `${prefix}.css.margin`,
      params,
    }),
    padding: getGivzQueryParam({
      key: `${prefix}.padding`,
      params,
    }),
  };
}

export function getGivzElementQueryParams(
  params: URLSearchParams,
  prefix: string,
): DeepNullablePartial<TextElement> {
  return {
    css: getGivzCssQueryParams(params, prefix),
    value: getGivzQueryParam({
      key: `${prefix}.value`,
      params,
    }),
  };
}

export function getGivzNumericQueryParam(
  key: string,
  params: URLSearchParams,
): number | undefined {
  const value = getGivzQueryParam({ key, params });
  const numericValue = Number(value);
  return isNaN(numericValue) ? undefined : numericValue;
}

export const getGivzQueryParam = <T>({
  key,
  params,
  splitBy,
  uppercase,
}: {
  key: string;
  params: URLSearchParams;
  splitBy?: string;
  uppercase?: boolean;
}): T | undefined => {
  let value: string | string[] | undefined | null = getGivzQueryParamForKey({
    key,
    params,
  });
  if (value && uppercase) value = value.toUpperCase();
  if (value && splitBy) value = value.split(splitBy);
  return value as unknown as T;
};

const getGivzQueryParamForKey = ({
  key,
  params,
}: {
  key: string;
  params: URLSearchParams;
}): string | null | undefined => {
  if (!params.has(key)) {
    return undefined;
  }

  const isPreview = isGivzPreview(params);
  let value = params.get(key);

  if (isPreview && (!value || value === nullValue)) {
    value = null;
  }

  return value;
};
