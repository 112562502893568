import { useEffect, useState } from 'react';
import styled from 'styled-components';

const OrganizationImageContainer = styled.div<{
  height: string;
  width: string;
}>`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: ${(props) => props.height};
  justify-content: center;

  width: ${(props) => props.width};
`;

const OrganizationImageElement = styled.img`
  height: auto;
  margin-top: 0.8rem;
  max-height: 70%;
  max-width: 85%;
  width: auto;
`;

export const OrganizationImage = (props: {
  height: string;
  src: string;
  width: string;
}) => {
  const { height, src, width } = props;
  const [imgSrc, setSrc] = useState(src);

  useEffect(() => {
    const img = new Image();
    img.src = src as string;
    img.addEventListener('load', () => setSrc(src));
  }, [src]);

  return (
    <OrganizationImageContainer height={height} width={width}>
      <OrganizationImageElement {...props} src={imgSrc} draggable={false} />
    </OrganizationImageContainer>
  );
};
