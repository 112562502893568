import { atom, selector } from 'recoil';
import { EmbeddedDonorAppData, HostedPageSettingsDto } from '@givz/core-client';
import { donationCreditState } from '../donation-credit';
import { givzQueryParamsState } from '../app';
import { mergeObjects } from '../../utils';

interface ExperienceData extends EmbeddedDonorAppData {
  landingPage?: HostedPageSettingsDto['landingPage'];
  socialSharingAmount: number;
}

export const shopifyExperienceDataState = atom<
  EmbeddedDonorAppData | undefined
>({
  default: undefined,
  key: 'shopifyExperienceDataState',
});

export const defaultSocialSharingAmount = 5;

export const experienceDataSelector = selector<ExperienceData | undefined>({
  get: ({ get }) => {
    const donationCredit = get(donationCreditState);
    const shopifyExperienceData = get(shopifyExperienceDataState);
    const overrides = get(givzQueryParamsState);

    let experienceData: ExperienceData | undefined;

    if (shopifyExperienceData) {
      experienceData = {
        ...shopifyExperienceData,
        formattedDonationCreditAmount:
          shopifyExperienceData.formattedDonationCreditAmount +
          (shopifyExperienceData.showUSD ? ' USD' : ''),
        socialSharingAmount:
          shopifyExperienceData.socialSharingAmount ??
          defaultSocialSharingAmount,
      };
    }

    if (donationCredit) {
      experienceData = {
        campaignGid: donationCredit.campaign.gid,
        campaignSlug: donationCredit.campaign.slug ?? '',
        collectionGid: donationCredit.campaign.collection.gid,
        consumerEmail: donationCredit.consumer?.email,
        consumerGid: donationCredit.consumer?.gid,
        description: donationCredit.donationWidgetSettings.description,
        donationCreditAmount: Number(donationCredit.amount.toFixed(2)),
        donationCreditGid: donationCredit.gid,
        donationCreditIsUnclaimed: donationCredit.isUnclaimed,
        donationGid: donationCredit.donation?.gid,
        donorName: donationCredit.donorName,
        emailPrompt: donationCredit.donationWidgetSettings.emailPrompt,
        emailRequired: donationCredit.donationWidgetSettings.emailRequired,
        formattedDonationCreditAmount: `$${donationCredit.amount.toFixed(2)} ${
          donationCredit.donationWidgetSettings.showUSD ? ' USD' : ''
        }`,
        formattedDonationCreditAmountFromCart:
          donationCredit.amountFromCart > 0
            ? `$${donationCredit.amountFromCart.toFixed(2)}`
            : undefined,
        formattedDonationCreditAmountFromPromotion: `$${(
          donationCredit.amount - donationCredit.amountFromCart
        ).toFixed(2)}`,
        hideSearchBar: donationCredit.donationWidgetSettings.hideSearchBar,
        landingPage: donationCredit.hostedPageSettings?.landingPage,
        primaryColor: donationCredit.donationWidgetSettings.primaryColor,
        selectedOrganizationGid: donationCredit.donation?.organization.gid,
        showUSD: donationCredit.donationWidgetSettings.showUSD,
        socialSharingAmount:
          donationCredit.donationWidgetSettings.socialSharingAmount ??
          defaultSocialSharingAmount,
        socialSharingEnabled:
          donationCredit.donationWidgetSettings.socialSharing,
        socialSharingHandles:
          donationCredit.donationWidgetSettings.socialSharingHandles,
        title: donationCredit.donationWidgetSettings.title,
        useHostedDonationPage: true,
      };
    }

    if (experienceData && overrides?.preview) {
      const merge = mergeObjects({
        excludeNull: false,
        excludeUndefined: true,
      });
      experienceData = {
        ...experienceData,
        description: merge(
          experienceData.description,
          overrides.donationWidgetSettings.description,
        ),
        emailPrompt:
          overrides.donationWidgetSettings.emailPrompt ??
          experienceData.emailPrompt,
        emailRequired:
          overrides.donationWidgetSettings.emailRequired ??
          experienceData.emailRequired,
        formattedDonationCreditAmount:
          overrides.donationWidgetSettings.showUSD &&
          !experienceData.formattedDonationCreditAmount.endsWith('USD')
            ? `${experienceData.formattedDonationCreditAmount} USD`
            : experienceData.formattedDonationCreditAmount,
        hideSearchBar:
          overrides.donationWidgetSettings.hideSearchBar ??
          experienceData.hideSearchBar,
        landingPage: merge(
          experienceData.landingPage,
          overrides.hostedPageSettings.landingPage,
        ),
        primaryColor:
          overrides.donationWidgetSettings.primaryColor ??
          experienceData.primaryColor,
        showUSD:
          overrides.donationWidgetSettings.showUSD ?? experienceData.showUSD,
        title: merge(
          experienceData.title,
          overrides.donationWidgetSettings.title,
        ),
      };
    }

    return experienceData;
  },
  key: 'experienceDataSelector',
});
