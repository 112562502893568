import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import {
  currentSocialShareModalNetworkState,
  givzQueryParamsState,
  showSocialShareModalState,
} from '../../states';
import { truthyStringToBoolean } from '../../utils';
import {
  getGivzElementQueryParams,
  getGivzNumericQueryParam,
  getGivzQueryParam,
  isGivzPreview,
} from '../../utils/givz-search-params';

export const useGetGivzQueryParamsFromUrl = () => {
  const [givzParams, setGivzParams] = useRecoilState(givzQueryParamsState);
  const [, setShowSocialShareModal] = useRecoilState(showSocialShareModalState);
  const [, setCurrentSocialShareModalNetwork] = useRecoilState(
    currentSocialShareModalNetworkState,
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    setGivzParams({
      amount: getGivzNumericQueryParam('amount', params),
      donationWidgetSettings: {
        description: getGivzElementQueryParams(
          params,
          'gz.donation.description',
        ),
        emailPrompt: getGivzQueryParam({
          key: 'gz.donation.emailPrompt',
          params,
          uppercase: true,
        }),
        emailRequired: truthyStringToBoolean(
          params.get('gz.donation.emailRequired'),
        )
          ? true
          : undefined,
        hideSearchBar: truthyStringToBoolean(
          params.get('gz.donation.hideSearchBar'),
        )
          ? true
          : undefined,
        primaryColor: getGivzQueryParam({
          key: 'gz.donation.primaryColor',
          params,
        }),
        showUSD: truthyStringToBoolean(params.get('gz.donation.showUSD'))
          ? true
          : undefined,
        title: getGivzElementQueryParams(params, 'gz.donation.title'),
      },
      hmac: getGivzQueryParam({ key: 'hmac', params }),
      hostedPageSettings: {
        landingPage: {
          backgroundColor: getGivzQueryParam({
            key: 'gz.landingPage.backgroundColor',
            params,
          }),
          description: getGivzElementQueryParams(
            params,
            'gz.landingPage.description',
          ),
          hero: getGivzElementQueryParams(params, 'gz.landingPage.hero'),
          logo: getGivzElementQueryParams(params, 'gz.landingPage.logo'),
          title: getGivzElementQueryParams(params, 'gz.landingPage.title'),
          widgetOnly: truthyStringToBoolean(
            params.get('gz.landingPage.widgetOnly'),
          )
            ? true
            : undefined,
        },
      },
      preview: isGivzPreview(params),
      reset: truthyStringToBoolean(params.get('gz.reset')),
      socialSharing: {
        modalOpen: truthyStringToBoolean(
          params.get('gz.socialSharing.modalOpen'),
        ),
        network: getGivzQueryParam({
          key: 'gz.socialSharing.network',
          params,
          uppercase: true,
        }),
      },
    });
  }, [setGivzParams]);

  useEffect(() => {
    if (givzParams?.socialSharing.modalOpen) {
      setShowSocialShareModal(givzParams?.socialSharing.modalOpen);
    }

    if (givzParams?.socialSharing.network) {
      setCurrentSocialShareModalNetwork(givzParams.socialSharing.network);
    }
  }, [
    givzParams?.socialSharing,
    setCurrentSocialShareModalNetwork,
    setShowSocialShareModal,
  ]);

  return givzParams;
};

const utmPrefix = 'utm_';
export const useGetUtmQueryParamsFromUrl = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const utmQueryParams: Record<string, string> = {};
  queryParams.forEach((value, key) => {
    if (key.startsWith(utmPrefix)) {
      utmQueryParams[key] = value;
    }
  });

  return utmQueryParams;
};
