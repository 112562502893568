import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  donationModalSelectedErrorState,
  donationModalSelectedOrganizationState,
  experienceDataSelector,
} from '../../states';
import { useCoreClient } from '../core-client';
import { errorsToString } from '../../utils';

export const useFetchSelectedOrganization = () => {
  const client = useCoreClient();
  const [
    donationModalSelectedOrganization,
    setDonationModalSelectedOrganization,
  ] = useRecoilState(donationModalSelectedOrganizationState);
  const experienceData = useRecoilValue(experienceDataSelector);
  const [, setErrorMessage] = useRecoilState(donationModalSelectedErrorState);

  const fetchOrganization = async (organizationGid: string) => {
    const { data, errors } = await client.Organizations.get({
      gid: organizationGid,
    });

    if (errors) {
      setErrorMessage(errorsToString(errors));
      Sentry.captureException(errors);
    } else {
      setDonationModalSelectedOrganization(data);
    }
  };

  useEffect(() => {
    const selectedOrganizationGid = experienceData?.selectedOrganizationGid;
    if (!donationModalSelectedOrganization && selectedOrganizationGid) {
      fetchOrganization(selectedOrganizationGid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    donationModalSelectedOrganization,
    experienceData?.selectedOrganizationGid,
  ]);

  return donationModalSelectedOrganization;
};
