import { givzDeviceIdKey } from '@givz/core-client/dist/client/client';
import { useEffect } from 'react';
import {
  browserName,
  deviceType,
  fullBrowserVersion,
  getUA,
  isMobile,
  osName,
} from 'react-device-detect';
import { useRecoilState, useRecoilValue } from 'recoil';
import { givzDeviceIdState, givzQueryParamsState } from '../../states';
import { BrowserStorage, plusYear } from '../../utils';
import { useCoreClient } from '../core-client';

export const useTrackDevice = () => {
  const givzQueryParams = useRecoilValue(givzQueryParamsState);
  const [givzDeviceId, setGivzDeviceId] = useRecoilState(givzDeviceIdState);
  const client = useCoreClient();
  useEffect(() => {
    const trackDevice = async () => {
      try {
        const deviceId = client.getDeviceId() as string;
        setGivzDeviceId(deviceId);
        BrowserStorage.set(givzDeviceIdKey, deviceId, {
          domain: '*.givz.com',
          expires: plusYear(2),
          path: '/',
          sameSite: 'lax',
        });
        await client.Track.device({
          deviceCreate: {
            metadata: {
              browserName,
              deviceType,
              fullBrowserVersion,
              isMobile,
              osName,
              ua: getUA,
            },
          },
          givzDeviceId: deviceId,
        });
      } catch (error) {
        console.warn((error as Error).message);
      }
    };

    if (givzQueryParams && !givzDeviceId && !givzQueryParams.preview) {
      trackDevice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [givzQueryParams, givzDeviceId]);
};
