import { FunctionComponent } from 'react';
import styled from 'styled-components';
import backIcon from './back-button.svg';

const BackIconContainer = styled.div`
  cursor: pointer;
  height: 24px;
  margin-bottom: 15px;
  margin-top: auto;
  width: 62px;
  &:hover {
    opacity: 0.5;
  }
`;

const BackIcon = styled.img`
  height: 100%;
  width: 100%;
`;

export const ModalBackButton: FunctionComponent<{
  onClick(): void;
}> = (props) => {
  const { onClick } = props;

  return (
    <BackIconContainer onClick={onClick}>
      <BackIcon src={backIcon}></BackIcon>
    </BackIconContainer>
  );
};
