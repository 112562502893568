import { Organization } from '@givz/core-client';
import { atom } from 'recoil';

export const showDonationModalState = atom<boolean>({
  default: false,
  key: 'showDonationModalState',
});

export const donationModalSelectedOrganizationState = atom<
  Organization | undefined
>({
  default: undefined,
  key: 'donationModalSelectedOrganizationState',
});

export const donationModalSelectedErrorState = atom<string | undefined>({
  default: undefined,
  key: 'donationModalSelectedErrorState',
});

export const showSocialShareModalState = atom<boolean>({
  default: false,
  key: 'showSocialShareModalState',
});

export enum SocialShareNetworks {
  AYNWHERE = 'ANYWHERE',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
}

export const currentSocialShareModalNetworkState = atom<SocialShareNetworks>({
  default: SocialShareNetworks.TWITTER,
  key: 'currentSocialShareModalNetworkState',
});
