import { CoreClient } from '@givz/core-client';
import packageInfo from '../../package.json';

const noEmptyStringsRule = {
  minLength: 1,
  type: 'string',
};

const requiredBaseEnvironmentVariables = {
  REACT_APP_CORE_API_BASE_URL: noEmptyStringsRule,
};

const requiredProductionEnvironmentVariables = {
  NODE_ENV: noEmptyStringsRule,
  REACT_APP_GIVZ_ENV: {
    enum: ['development', 'staging', 'production'],
  },
  REACT_APP_SENTRY_DSN: noEmptyStringsRule,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const environmentSchema = {
  properties:
    process.env.NODE_ENV === 'production'
      ? {
          ...requiredBaseEnvironmentVariables,
          ...requiredProductionEnvironmentVariables,
        }
      : requiredBaseEnvironmentVariables,
  required:
    process.env.NODE_ENV === 'production'
      ? [
          ...Object.keys(requiredBaseEnvironmentVariables),
          ...Object.keys(requiredProductionEnvironmentVariables),
        ]
      : Object.keys(requiredBaseEnvironmentVariables),
  type: 'object',
};
/*
envSchema({
  dotenv: true,
  schema: environmentSchema,
});*/

export enum GivzEnvType {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export type Environment = {
  app: {
    env: GivzEnvType;
  };
  core: {
    url: string;
  };
  sentry: {
    dsn: string;
  };
};
export const environment: Environment = {
  app: {
    env:
      (process.env.REACT_APP_GIVZ_ENV as GivzEnvType) ||
      GivzEnvType.DEVELOPMENT,
  },
  core: {
    url: process.env.REACT_APP_CORE_API_BASE_URL as string,
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN as string,
  },
};

export const ClientInit = (deviceId?: string) => {
  return CoreClient(
    process.env.REACT_APP_CORE_API_BASE_URL as string,
    `donor-app@${packageInfo.version}`,
    undefined,
    undefined,
    deviceId,
  );
};
