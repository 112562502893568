import React, { FunctionComponent, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import {
  ActionButton,
  ErrorMessage,
  FlexboxContainer,
  HeaderDiv,
  Input,
  InputContainer,
  TextDiv,
} from '../../common';
import ModalContainer from '../../common/modal/container';
import {
  currentSocialShareModalNetworkState,
  SocialShareNetworks,
} from '../../../states/modal';
import { useCoreClient } from '../../../hooks';
import {
  donationModalSelectedOrganizationState,
  experienceDataSelector,
  showSocialShareModalState,
} from '../../../states';
import { getRandomElementFromArray, openUrlInNewTab } from '../../../utils';
import SocialSharingButtons from '../buttons';
import { defaultSocialSharingAmount } from '../../../states/experience';

const Container = styled(FlexboxContainer)`
  align-items: center;
  background-color: #fff;
  flex-direction: column;

  ${TextDiv} {
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }
`;

const ShareLink = styled.a`
  color: #37746b;
  cursor: pointer;
  text-decoration: none;
`;

const InstagramInputContainer = styled(InputContainer)`
  width: 91%;

  @media (max-width: 460px) {
    width: 100%;
  }
`;

const defaultInstagramErrorMessage = 'There was an issue sharing to Instagram';

const SocialSharingModal: FunctionComponent = () => {
  const coreClient = useCoreClient();
  const selectedSocialShareNetwork = useRecoilValue(
    currentSocialShareModalNetworkState,
  );
  const [showSocialShareModal, setShowSocialShareModal] = useRecoilState(
    showSocialShareModalState,
  );
  const experienceData = useRecoilValue(experienceDataSelector);
  const organization = useRecoilValue(donationModalSelectedOrganizationState);
  const [instagramUsername, setInstagramUsername] = useState('');
  const [instagramPostUrl, setInstagramPostUrl] = useState<
    string | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const shouldRenderDescription = experienceData && organization;

  const getTwitterShareUrl = () => {
    const organizationName = organization?.socialHandles?.twitter
      ? `@${organization.socialHandles.twitter}`
      : organization?.name;

    const accountName = experienceData?.socialSharingHandles?.twitter
      ? `@${experienceData?.socialSharingHandles?.twitter}`
      : experienceData?.donorName;

    let tweets = [
      `I chose to support ${organizationName} with my purchase from ${accountName} #EmpoweredByGivz`,
      `My purchase at ${accountName} made an impact! I chose ${organizationName} to receive a donation`,
    ];

    if (organization?.tags) {
      const socialTags = organization.tags.filter((tag) => tag.startsWith('#'));

      tweets = tweets.map((tweet) => `${tweet} ${socialTags?.join(' ')}`);
    }

    const tweet = encodeURIComponent(
      getRandomElementFromArray(tweets) as string,
    );

    return `https://twitter.com/intent/tweet?text=${tweet}&via=getgivz`;
  };

  const handleCloseModal = () => setShowSocialShareModal(false);

  const handleInstagramUsernameOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => setInstagramUsername(event.target.value.trim());

  const handleShareToInstagram = async () => {
    if (!experienceData?.donationGid) {
      setError('Could not share to Instagram.');
      return;
    }

    if (instagramUsername === '') {
      setError('Enter your Instagram username.');
      return;
    }

    setError('');

    setLoading(true);

    try {
      const { data, errors } = await coreClient.Donations.share({
        gid: experienceData.donationGid,
        instagramUsername,
      });

      if (errors) {
        setError(defaultInstagramErrorMessage);
      } else {
        setInstagramPostUrl(data.instagramPostUrl);
      }
    } catch (error) {
      setError(defaultInstagramErrorMessage);
      Sentry.captureException(error);
    }

    setLoading(false);
  };

  const openInstagramPostInNewTab = () =>
    instagramPostUrl && openUrlInNewTab(instagramPostUrl);

  const openTwitterShareUrlInNewTab = () =>
    openUrlInNewTab(getTwitterShareUrl());

  const socialSharingAmount =
    experienceData?.socialSharingAmount ?? defaultSocialSharingAmount;

  return (
    <ModalContainer open={showSocialShareModal} onClose={handleCloseModal}>
      <SocialSharingButtons hideText={true} />

      <Container>
        <HeaderDiv>
          Share and we'll donate another ${socialSharingAmount}
        </HeaderDiv>

        {shouldRenderDescription &&
          selectedSocialShareNetwork === SocialShareNetworks.TWITTER && (
            <>
              <TextDiv>
                Tweet about your {experienceData?.formattedDonationCreditAmount}{' '}
                donation to {organization.name}
              </TextDiv>
              <ActionButton onClick={openTwitterShareUrlInNewTab}>
                Share on Twitter
              </ActionButton>
            </>
          )}
        {shouldRenderDescription &&
          selectedSocialShareNetwork === SocialShareNetworks.INSTAGRAM && (
            <>
              <TextDiv>
                Enter your Instagram username below and we'll tag you in a post
                on our{' '}
                <ShareLink
                  target="_blank"
                  href="https://instagram.com/givzimpact"
                >
                  @GivzImpact
                </ShareLink>{' '}
                instagram account.
              </TextDiv>
              {!instagramPostUrl && (
                <>
                  <InstagramInputContainer>
                    <Input
                      placeholder="Enter your Instagram username without the '@'"
                      value={instagramUsername}
                      onChange={handleInstagramUsernameOnChange}
                    />
                  </InstagramInputContainer>
                  {error && <ErrorMessage>{error}</ErrorMessage>}

                  <ActionButton
                    loading={loading}
                    onClick={handleShareToInstagram}
                  >
                    {loading ? '' : 'Share on Instagram'}
                  </ActionButton>
                </>
              )}
              {instagramPostUrl && (
                <ActionButton
                  loading={loading}
                  onClick={openInstagramPostInNewTab}
                >
                  View the post on Instagram
                </ActionButton>
              )}
            </>
          )}
        {shouldRenderDescription &&
          selectedSocialShareNetwork === SocialShareNetworks.AYNWHERE && (
            <TextDiv>
              Do something creative, post on your favorite platforms, and then
              email us a screenshot or link to{' '}
              <ShareLink target="_blank" href="mailto:sharing@givz.com">
                sharing@givz.com
              </ShareLink>
              .
            </TextDiv>
          )}
      </Container>
    </ModalContainer>
  );
};

export default SocialSharingModal;
