import { FunctionComponent } from 'react';
import styled from 'styled-components';
import ukraineFlagBadge from '../../common/badges/ukraine-flag-badge.svg';
import theme from '../../../styles/theme';
import { FlexboxContainer, SvgContainer } from '../../common';

const BadgeContainer = styled(FlexboxContainer)`
  align-items: center;
  height: 40px;
  padding-left: 14px;

  position: absolute;
  ${SvgContainer} {
    margin-right: 0.3rem;
  }
`;

const BadgeText = styled.div<{
  color?: string;
}>`
  color: ${(props) => props.color ?? 'rgba(0, 0, 0, 0.6)'};
  font-size: 14px;
`;

const Badge: FunctionComponent<{
  tag: string;
}> = (props) => {
  const { tag } = props;
  const hasUkraineBadge = tag === '#Ukraine';

  return (
    <BadgeContainer>
      {hasUkraineBadge && <SvgContainer src={ukraineFlagBadge} />}
      <BadgeText
        color={hasUkraineBadge ? theme.colors.ukraineBlue.hex : undefined}
      >
        {tag}
      </BadgeText>
    </BadgeContainer>
  );
};

export default Badge;
