import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { EmbeddedDonorAppData, Organization } from '@givz/core-client';
import { useRecoilState, useRecoilValue } from 'recoil';
import Skeleton from 'react-loading-skeleton';
import { OrganizationSearchBox } from '../organization-search-box';
import { Divider, HeaderDiv, Separator, TextDiv } from '../common';
import { DonationModal } from '../donation-modal';
import { FeaturedOrganizations } from '../featured-organizations';
import {
  donationModalSelectedOrganizationState,
  experienceDataSelector,
  isEmbeddedState,
  isInModalState,
  isInPreviewModeSelector,
  organizationsSearchState,
  showDonationModalState,
  showSocialShareModalState,
} from '../../states';
import { useCoreClient, useFetchSelectedOrganization } from '../../hooks';
import SocialSharingModal from '../social-sharing/modal';
import 'react-loading-skeleton/dist/skeleton.css';

const Container = styled.div<{
  isInModal: boolean;
  isInPreviewMode: boolean;
  searchFocused: boolean;
}>`
  background-color: #fff;
  margin-top: ${(props) =>
    props.isInModal ? (props.isInPreviewMode ? '110px' : '65px') : undefined};
  padding: 20px;
`;

const ContentContainer = styled.div<{
  hideScroll: boolean;
}>`
  border-top: none;
  box-sizing: border-box;
  height: calc(100% - 65px);
  width: 100%;
  ${(props) =>
    props.hideScroll &&
    css`
      overflow: hidden;
      padding: 0;
    `}
`;

const Disclaimer = styled.div`
  color: #3c3c3c;
  font-family: 'Roboto-Regular', sans-serif;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 15px;
`;

const DisclaimerLink = styled.a`
  color: #37746b;
  cursor: pointer;
  text-decoration: none;
`;

export const DonationForm = () => {
  useFetchSelectedOrganization();
  const client = useCoreClient();
  const isEmbedded = useRecoilValue(isEmbeddedState);
  const experienceData = useRecoilValue(experienceDataSelector);
  const [featuredOrganizations, setFeaturedOrganizations] = useState<
    Organization[]
  >([]);
  const [searchFocused, setSearchFocused] = useState(false);
  const [search, setSearch] = useRecoilState(organizationsSearchState);
  const [, setDonationModalSelectedOrganization] = useRecoilState(
    donationModalSelectedOrganizationState,
  );
  const [showDonationModal, setShowDonationModal] = useRecoilState(
    showDonationModalState,
  );
  const showSocialShareModal = useRecoilValue(showSocialShareModalState);
  const isInModal = useRecoilValue(isInModalState);
  const isInPreviewMode = useRecoilValue(isInPreviewModeSelector);
  const containerRef = useRef<HTMLDivElement>(null);

  // TODO: check if paginatation is needed
  const loadFeaturedOrganizations = async (
    experienceData: EmbeddedDonorAppData | undefined,
  ): Promise<void> => {
    if (!experienceData?.campaignGid) return;
    try {
      const { data, errors } = await client.Collections.get({
        gid: experienceData.collectionGid,
      });
      if (errors) {
        throw errors;
      }
      setFeaturedOrganizations(data.organizations);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const toggleDonationFormModal = (organization: Organization | undefined) => {
    if (isEmbedded) {
      window.scrollTo({ top: 0 });
    }
    setDonationModalSelectedOrganization(organization);
    setShowDonationModal(!showDonationModal);
  };

  const onFocus = () => {
    if (isEmbedded) {
      window.scrollTo({ top: 0 });
    }
    setSearchFocused(true);
  };

  const onBlur = () => {
    setSearchFocused(false);
  };

  useEffect(() => {
    loadFeaturedOrganizations(experienceData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceData]);

  useEffect(() => {
    if (search !== '' && !searchFocused && !showDonationModal) setSearch('');
  }, [searchFocused, showDonationModal, search, setSearch]);

  const showSearchBar =
    experienceData?.donationCreditIsUnclaimed && !experienceData?.hideSearchBar;

  return (
    <Container
      ref={containerRef}
      isInModal={isInModal}
      isInPreviewMode={isInPreviewMode ?? false}
      searchFocused={searchFocused}
    >
      {!showDonationModal && (
        <ContentContainer hideScroll={searchFocused}>
          {!experienceData && <Skeleton count={2} height={35} />}
          {!searchFocused && (
            <>
              <HeaderDiv css={experienceData?.title.css}>
                {experienceData?.title.value}
              </HeaderDiv>
              <Divider pixels={10}></Divider>
              <TextDiv css={experienceData?.description.css}>
                {experienceData?.description.value}
              </TextDiv>
              <Separator />
            </>
          )}
          {showSearchBar && (
            <OrganizationSearchBox
              featuredOrganizations={featuredOrganizations}
              onBlur={onBlur}
              onFocus={onFocus}
              toggleModal={toggleDonationFormModal}
              searchFocused={searchFocused}
            />
          )}

          {!searchFocused && (
            <>
              {!experienceData && <Skeleton height={200} />}
              {experienceData?.collectionGid && <Divider pixels={20}></Divider>}
              {experienceData?.collectionGid && (
                <FeaturedOrganizations
                  donationCreditIsUnclaimed={
                    experienceData?.donationCreditIsUnclaimed
                  }
                  featuredOrganizations={featuredOrganizations}
                  toggleModal={toggleDonationFormModal}
                  parentContainerRef={containerRef}
                />
              )}
              <Separator />
              <Disclaimer>
                These highlighted charities have not endorsed the campaign. You
                can choose your favorite charity from PayPal Giving Fund
                enrolled charities. The donation will be made to PayPal Giving
                Fund, a 501(c)(3) charity, and is subject to PayPal Giving
                Fund's{' '}
                <DisclaimerLink
                  href="https://www.paypal.com/us/webapps/mpp/givingfund/policies/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </DisclaimerLink>
                ,{' '}
                <DisclaimerLink
                  href="https://www.paypal.com/us/webapps/mpp/givingfund/policies/donor-terms-of-service"
                  target="_blank"
                >
                  Donor Terms
                </DisclaimerLink>
                , and{' '}
                <DisclaimerLink
                  href="https://www.paypal.com/us/webapps/mpp/givingfund/policies/donation-delivery-policy"
                  target="_blank"
                >
                  Donation Delivery Policy
                </DisclaimerLink>
                . The charities you select will typically receive the funds{' '}
                <DisclaimerLink
                  href="https://www.paypal.com/us/webapps/mpp/givingfund/policies/donation-delivery-policy#statement-2"
                  target="_blank"
                >
                  within 15-45 days
                </DisclaimerLink>{' '}
                of the donation made by the Donor via Givz. Your selection does
                not qualify as a tax deductible donation.
              </Disclaimer>
            </>
          )}
        </ContentContainer>
      )}
      {experienceData && showDonationModal && (
        <DonationModal
          {...experienceData}
          onToggleModal={toggleDonationFormModal}
        />
      )}
      {showSocialShareModal && experienceData?.donationGid && (
        <SocialSharingModal />
      )}
    </Container>
  );
};
