import { FunctionComponent } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import {
  currentSocialShareModalNetworkState,
  showSocialShareModalState,
} from '../../../states/';
import { SocialShareNetworks } from '../../../states/modal';
import { FlexboxContainer, Separator, SvgContainer } from '../../common';
import { GlobeIcon, InstagramIcon, TwitterIcon } from '../icons';
import { defaultSocialSharingAmount } from '../../../states/experience';

const Container = styled(FlexboxContainer)`
  align-items: center;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 1rem 0;
`;

const Button = styled.div`
  cursor: pointer;
  margin: 0 0.6rem;

  ${SvgContainer} {
    height: 30px;
    width: auto;
  }
`;

const Link = styled.span`
  color: ${({ theme }) => theme.colors.gzPrimary.hex};
  cursor: pointer;
`;

const SocialSharingButtons: FunctionComponent<{
  hideText?: boolean;
  amount?: number;
}> = (props) => {
  const { hideText, amount = defaultSocialSharingAmount } = props;
  const [, setShowSocialShareModal] = useRecoilState(showSocialShareModalState);
  const [, setCurrentSocialShareModalNetwork] = useRecoilState(
    currentSocialShareModalNetworkState,
  );

  const handleButtonClick = (socialNetwork: SocialShareNetworks) => {
    setCurrentSocialShareModalNetwork(socialNetwork);
    setShowSocialShareModal(true);
  };

  return (
    <Container>
      {!hideText && (
        <>
          <Text>
            <Link
              onClick={() => handleButtonClick(SocialShareNetworks.TWITTER)}
            >
              Share
            </Link>{' '}
            and we'll donate another ${amount}
          </Text>
        </>
      )}
      <FlexboxContainer>
        <Button onClick={() => handleButtonClick(SocialShareNetworks.TWITTER)}>
          <SvgContainer src={TwitterIcon} />
        </Button>
        <Button
          onClick={() => handleButtonClick(SocialShareNetworks.INSTAGRAM)}
        >
          <SvgContainer src={InstagramIcon} />
        </Button>
        <Button onClick={() => handleButtonClick(SocialShareNetworks.AYNWHERE)}>
          <SvgContainer src={GlobeIcon} />
        </Button>
      </FlexboxContainer>
      {hideText && <Separator />}
    </Container>
  );
};

export default SocialSharingButtons;
