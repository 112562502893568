import { FunctionComponent } from 'react';
import { Organization, TextElementCss } from '@givz/core-client';
import { useRecoilState } from 'recoil';
import styled, { css, keyframes } from 'styled-components';
import { isPreviewBannerClosedState } from '../../states/app';
import theme from '../../styles/theme';
import ukraineButtonBadge from './badges/ukraine-button-badge.svg';

export const FlexboxContainer = styled.div`
  display: flex;
`;

export const SvgContainer = styled.img`
  height: 100%;
  width: 100%;
`;

export const LoadingKeyframes = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const ActionButton = styled.div<{
  loading?: boolean;
  disabled?: boolean;
  hasGreenBackground?: boolean;
}>`
  align-items: center;
  background-color: ${({ disabled, hasGreenBackground, theme }) =>
    disabled && !hasGreenBackground ? 'gray' : theme.colors.primary.hex};
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16), 0 3px 4px 0 rgba(0, 0, 0, 0.16),
    0 2px 4px 0 rgba(0, 0, 0, 0.16);
  color: white;
  cursor: ${(props) => (props.disabled ? undefined : 'pointer')};
  display: flex;
  font-family: 'Roboto-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  margin: 20px 0 0;
  width: 100%;
  ${(props) =>
    props.loading &&
    css`
      cursor: inherit;
      opacity: 0.7;
      &:after {
        animation: ${LoadingKeyframes} 1.2s linear infinite;
        border: 2px solid white;
        border-color: white transparent white transparent;
        border-radius: 50%;
        content: ' ';
        display: block;
        height: 20px;
        margin: -2px auto 0;
        width: 20px;
      }

      div,
      span,
      p {
        display: none;
      }
    `}
  ${(props) =>
    props.loading &&
    !props.disabled &&
    css`
      &:hover {
        opacity: 0.9;
      }
    `}
`;

export const DonateButtonContainer = styled.div<{
  color?: string;
}>`
  background-color: #fff;
  border: 1px solid #e4e5e7;
  border-radius: 8px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  color: ${(props) => props.color ?? '#000'};
  cursor: pointer;
  font-family: 'Roboto-Medium', sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  padding: 9px 16px;
`;

const DonateButtonChildrenContainer = styled(FlexboxContainer)`
  ${SvgContainer} {
    height: 13px;
    margin-left: 0.3rem;
  }
`;

export const DonateButton: FunctionComponent<{
  onClick?(): void;
  organizationTags?: string[];
}> = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, onClick, organizationTags } = props;
  const hasUkraineBadging = organizationTags?.includes('#Ukraine');

  return (
    <DonateButtonContainer
      onClick={() => onClick && onClick()}
      color={hasUkraineBadging ? theme.colors.ukraineBlue.hex : undefined}
    >
      <DonateButtonChildrenContainer>
        {children}
        {hasUkraineBadging && <SvgContainer src={ukraineButtonBadge} />}
      </DonateButtonChildrenContainer>
    </DonateButtonContainer>
  );
};

export const Divider = styled.div<{ pixels: number }>`
  height: ${(props) => props.pixels}px;
  position: relative;
`;

export const ErrorMessage = styled.div`
  color: red;
  font-family: 'Roboto-Regular', sans-serif;
  font-size: 16px;
  font-weight: bolder;
  letter-spacing: 0px;
  line-height: 21px;
  margin: 10px 0 0;
  text-align: center;
  width: 100%;
`;

export const HeaderDiv = styled.div<{
  css?: TextElementCss;
}>`
  color: #000000;
  font-family: 'Roboto-Bold', sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 30px;
  @media (max-width: 460px) {
    font-size: 18px;
    line-height: 24px;
  }

  // TODO: find a way to refactor this to only one place and build overrides
  font: ${({ css }) => (css?.font ? css.font : undefined)};
  margin: ${({ css }) => (css?.margin ? css.margin : undefined)};
  padding: ${({ css }) => (css?.padding ? css.padding : undefined)};
`;

export const InputContainer = styled.div`
  border: 1px solid rgb(226, 226, 226);
  border-radius: 4px;
  margin: 20px 0 0;
  padding: 5px 20px;

  input {
    height: 40px;
  }
`;

export const Input = styled.input`
  border: none !important;
  color: #000000;
  font-family: 'Roboto-Regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  letter-spacing: 0px;
  line-height: 23px;
  outline: none;
  width: 100%;
  ::placeholder {
    color: #545454;
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 23px;
    @media (max-width: 460px) {
      font-size: 12px;
      line-height: 20px;
    }
  }
`;

export const TextDiv = styled.div<{
  css?: TextElementCss;
}>`
  color: #000000;
  font-family: 'Roboto-Regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  @media (max-width: 460px) {
    font-size: 14px;
    line-height: 21px;
  }

  // TODO: find a way to refactor this to only one place and build overrides
  font: ${({ css }) => (css?.font ? css.font : undefined)};
  margin: ${({ css }) => (css?.margin ? css.margin : undefined)};
  padding: ${({ css }) => (css?.padding ? css.padding : undefined)};
`;

export const Separator = styled.hr`
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  height: 0;
  margin: 20px 0;
  overflow: visible;
  width: 100%;
`;

export const DottedSeparator = styled(Separator)`
  border-top: 1px dotted #37746b;
`;

const StyledPreviewModeBanner = styled.div<{
  isInHostedMode?: boolean;
  isPreviewBannerClosed: boolean;
}>`
  align-items: center;
  background-color: #9d9d9d;
  color: #fff;
  display: ${(props) => (props.isPreviewBannerClosed ? 'none' : 'flex')};
  font-weight: bold;
  height: 45px;
  justify-content: center;
  position: ${(props) => (props.isInHostedMode ? 'fixed' : undefined)};
  text-align: center;
  width: 100%;
  z-index: 9999;
`;

const ClosePreviewModeBannerButton = styled.span`
  align-items: flex-end;
  border: 1px solid white;
  border-radius: 6px;
  display: flex;
  height: 20px;
  justify-content: flex-end;
  margin: 0 0 0 20px;
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

export const OrganizationMetadataContainer = styled.div`
  color: #000000;
  font-family: 'Roboto-Regular', sans-serif;
  font-size: 10px !important;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
`;

export const OrganizationMetadata: FunctionComponent<{
  organization: Organization;
}> = (props) => {
  const { organization } = props;
  const { city, country, ein, state } = organization;

  // TODO - consider centralizing this rendering logic and/or move to core
  //
  const locationParts = [city, state].filter(Boolean); // Remove undefined's
  let metadata = locationParts.join(', ');

  const countries: Record<string, string> = {
    AU: 'Australia',
    CA: 'Canada',
    GB: 'U.K.',
    HK: 'Hong Kong',
    IE: 'Ireland',
    IN: 'India',
    US: 'U.S.',
  };

  if (country === 'US' && ein) {
    metadata += ` | ${ein}`;
  } else {
    const countryLong = countries[country];
    metadata += countryLong && ` | ${countryLong}`;
  }

  return (
    <OrganizationMetadataContainer>{metadata}</OrganizationMetadataContainer>
  );
};

export const PreviewModeBanner: FunctionComponent<{
  isInHostedMode?: boolean;
}> = (props) => {
  const { isInHostedMode } = props;

  const [isPreviewBannerClosed, setIsPreviewBannerClosed] = useRecoilState(
    isPreviewBannerClosedState,
  );

  return (
    <StyledPreviewModeBanner
      isInHostedMode={isInHostedMode}
      isPreviewBannerClosed={isPreviewBannerClosed}
    >
      &#9888;&#65039; Preview Mode. Donations are disabled.{' '}
      <ClosePreviewModeBannerButton
        onClick={() => setIsPreviewBannerClosed(true)}
      >
        Dismiss
      </ClosePreviewModeBannerButton>
    </StyledPreviewModeBanner>
  );
};
