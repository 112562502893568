import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { useUnsubscribeConsumer } from '../../hooks/unsubscribe';
import { errorMessageSelector, errorState } from '../../states';
import { ErrorType } from '../../states/error';
import givzLogo from '../header/givz-logo.svg';
import Error from '../error';
import poweredByGivzLogo from './powered-by-givz-green-logo.svg';

const MainContainer = styled.div`
  align-items: center;
  background-color: rgb(248, 247, 247);
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
`;

const Box = styled.div`
  background-color: #f9f5ea;
  width: 600px;
`;

const Content = styled.div`
  padding: 30px;
`;

const Nav = styled.nav`
  background-color: #f9f5ea;
  padding: 30px 30px 0 30px;
`;

const Title = styled.p`
  color: #262626;
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.25;
  margin: 0;
  padding: 0;
  text-align: left;
`;

const Subtitle = styled.p`
  color: #262626;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  margin-bottom: 10px;
  text-align: left; ;
`;

const Footer = styled.footer`
  align-items: center;
  border-top: 1px solid #e2e2e2;
  display: flex;
  justify-content: space-between;
  padding: 30px;

  img {
    width: 150px;
    fill: red;
  }

  span {
    color: #262626;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 30px;
    text-align: right;
    text-decoration: none;
  }
`;

export const ConsumerUnsubscribe = () => {
  useUnsubscribeConsumer();
  const appError = useRecoilValue(errorState);
  const appErrorMessage = useRecoilValue(errorMessageSelector);

  if (
    appErrorMessage &&
    appError &&
    [
      ErrorType.CONSUMER_UNSUBSCRIBE_TOKEN_INVALID,
      ErrorType.CONSUMER_UNSUBSCRIBE_TOKEN_MISSING,
    ].includes(appError)
  ) {
    return <Error appError={appError} appErrorMessage={appErrorMessage} />;
  }

  return (
    <MainContainer>
      <Box>
        <Nav>
          <img src={givzLogo} />
        </Nav>
        <Content>
          <Title>You are unsubscribed</Title>
          <Subtitle> You will no longer receive emails of this type</Subtitle>
        </Content>
        <Footer>
          <img src={poweredByGivzLogo} alt="Powered by Givz" />
          <span>Givz, Inc. 300 East 93rd Street, New York, NY 10128</span>
        </Footer>
      </Box>
    </MainContainer>
  );
};
