import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import {
  DonationCreate,
  EmailPrompt,
  EmbeddedDonorAppData,
  Organization,
} from '@givz/core-client';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  ActionButton,
  Divider,
  ErrorMessage,
  HeaderDiv,
  Input,
  InputContainer,
  OrganizationMetadata,
  OrganizationMetadataContainer,
  Separator,
  TextDiv,
} from '../common';
import { ModalBackButton } from '../common/modal';
import { OrganizationImage } from '../organization-image';
import {
  donationCreditState,
  donationModalSelectedErrorState,
  experienceDataSelector,
  givzDeviceIdState,
  isInPreviewModeSelector,
  shopifyExperienceDataState,
} from '../../states';
import { errorsToString } from '../../utils';
import { useCoreClient, useFetchSelectedOrganization } from '../../hooks';
import SocialSharingButtons from '../social-sharing/buttons';
import { useGetUtmQueryParamsFromUrl } from '../../hooks/app';
import checkmarkIcon from './checkmark.svg';
import 'react-loading-skeleton/dist/skeleton.css';

type DonationModalProps = Omit<
  EmbeddedDonorAppData,
  'title' | 'description' | 'collectionId'
> & {
  onToggleModal: (organization?: Organization | undefined) => void;
};

enum DonationStatus {
  DONE = 'DONE',
  LOADING = 'LOADING',
  PENDING = 'PENDING',
}

const Container = styled.div`
  background-color: white;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
`;

const DonationForm = styled.div`
  box-sizing: border-box;
  height: calc(100%);
  width: 100%;
`;

const ContentContainer = styled.div`
  border-top: none;
  box-sizing: border-box;
  height: 100%;
  padding: 0;
  width: 100%;
`;

const OrganizationCard = styled.div`
  background-color: #ffffff;
  border: solid 1px #e2e2e2;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  min-height: 170px;
  width: 100%;
  @media (max-width: 460px) {
    box-sizing: border-box;
    flex-direction: column;
    height: auto;
    padding: 10px;
  }
`;

const OrganizationImageContainer = styled.div`
  height: 170px;
  width: 154px;
  @media (max-width: 460px) {
    height: 140px;
    width: 100%;
  }
`;

const OrganizationDescriptionContainer = styled.div`
  border-left: solid 1px #e2e2e2;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 170px;
  padding: 16px 16px 21px 17px;
  width: calc(100% - 154px);
  @media (max-width: 460px) {
    border-left: none;
    flex-direction: column;
    padding: 10px 10px 0;
    width: 100%;
  }
`;

const OrganizationName = styled.div`
  color: #000000;
  font-family: 'Roboto-Bold', sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24px;
`;

const StyledOrganizationDescription = styled.div<{
  expanded?: boolean;
}>`
  border-radius: 5px;
  color: #000000;
  font-family: 'Roboto-Regular', sans-serif;
  font-size: 14px;
  font-weight: 400;
  height: calc(100% - 53px);
  letter-spacing: 0px;
  line-height: 20px;
  margin: 9px 0 0;
  max-height: ${(props) => (props.expanded ? undefined : 'calc(100% - 53px)')};
  overflow: ${(props) => (props.expanded ? undefined : 'hidden')};
  @media (max-width: 460px) {
    min-height: 80px;
  }
`;

const ExpandButton = styled.div`
  color: #37746b;
  cursor: pointer;
  margin-top: 0.25rem;
`;

const OrganizationDescription = (props: {
  description?: string;
  name?: string;
}) => {
  const maxDescriptionLength = 120;
  const [expanded, setExpanded] = useState(false);
  const [formattedDescription, setFormattedDescription] = useState<
    string | undefined
  >(undefined);

  const { description, name } = props;

  useEffect(() => {
    setExpanded(false);
  }, [name]);

  useEffect(() => {
    if (!expanded && description && description.length > maxDescriptionLength) {
      setFormattedDescription(
        description.substring(0, maxDescriptionLength) + '...',
      );
    } else {
      setFormattedDescription(description);
    }
  }, [description, expanded]);

  if (!name) {
    return <Skeleton height="100%" />;
  }

  return (
    <StyledOrganizationDescription expanded={expanded}>
      <>
        {formattedDescription}
        {((description !== formattedDescription && !expanded) || expanded) && (
          <ExpandButton onClick={() => setExpanded(!expanded)}>
            {!expanded && 'More'}
            {expanded && 'Less'}
          </ExpandButton>
        )}
      </>
    </StyledOrganizationDescription>
  );
};

const DonateCompleteIcon = styled.img`
  height: 20px;
  margin: 0 10px 0;
  width: 20px;
`;

const DonateCompleteText = styled.div``;

const Disclaimer = styled.div`
  color: #6c6c6c;
  font-family: 'Roboto-Regular', sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 21px;
  margin: 0 auto;
  text-align: center;
  width: 70%;
`;

const DisclaimerLink = styled.a`
  color: #37746b;
  cursor: pointer;
  text-decoration: none;
`;

const CompleteText = styled.div`
  color: #000000;
  font-family: 'Roboto-Regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  margin: 0 auto;
  text-align: center;
  width: 82%;
`;

const CompleteSubText = styled(CompleteText)`
  font-size: 14px;
`;

const getShowEmailInput = ({
  consumerEmail,
  donationStatus,
  emailPrompt,
}: {
  consumerEmail?: string;
  donationStatus: DonationStatus;
  emailPrompt?: EmailPrompt;
}) => {
  if (donationStatus === DonationStatus.DONE) return false;
  if (emailPrompt === EmailPrompt.IfMissing && !consumerEmail) return true;
  return emailPrompt === EmailPrompt.On;
};

export const DonationModal = (props: DonationModalProps) => {
  const {
    campaignGid,
    consumerEmail,
    consumerGid,
    donationCreditAmount,
    donationCreditGid,
    donationGid,
    donorName,
    emailPrompt,
    emailRequired,
    formattedDonationCreditAmount,
    formattedDonationCreditAmountFromCart,
    formattedDonationCreditAmountFromPromotion,
    onToggleModal,
    socialSharingEnabled,
  } = props;
  const client = useCoreClient();
  const utmQueryParams = useGetUtmQueryParamsFromUrl();
  const givzDeviceId = useRecoilValue(givzDeviceIdState);
  const isInPreviewMode = useRecoilValue(isInPreviewModeSelector);
  const [donationCredit, setDonationCredit] =
    useRecoilState(donationCreditState);

  const [shopifyExperienceData, setShopifyExperienceData] = useRecoilState(
    shopifyExperienceDataState,
  );

  const experienceData = useRecoilValue(experienceDataSelector);
  const [donationStatus, setDonationStatus] = useState<DonationStatus>(
    donationGid ? DonationStatus.DONE : DonationStatus.PENDING,
  );

  const [emailInput, setEmailInput] = useState(consumerEmail ?? '');
  const [errorMessage, setErrorMessage] = useRecoilState(
    donationModalSelectedErrorState,
  );

  const organization = useFetchSelectedOrganization();

  const [showEmailInput, setShowEmailInput] = useState(
    getShowEmailInput({ ...props, donationStatus }),
  );

  useEffect(() => {
    setShowEmailInput(getShowEmailInput({ ...props, donationStatus }));
  }, [consumerEmail, emailPrompt, donationStatus, props]);

  const donate = async (): Promise<void> => {
    setErrorMessage('');

    let email: string | undefined = emailInput.trim();
    if (email.length === 0) email = undefined;
    if (emailRequired && !email) {
      setErrorMessage('Email required');
      return;
    }

    if (!givzDeviceId || !organization?.gid) {
      setErrorMessage('Error completing donation');
      return;
    }

    setDonationStatus(DonationStatus.LOADING);
    const createData: DonationCreate = {
      amountPledged: donationCreditAmount,
      attribution: utmQueryParams,
      campaignGid,
      consumerGid,
      donationCreditGid,
      email,
      organizationGid: organization.gid,
    };
    const { data, errors } = await client.Donations.create({
      donationCreate: createData,
      givzDeviceId,
    });
    if (errors) {
      setErrorMessage(errorsToString(errors));
      setDonationStatus(DonationStatus.PENDING);
      Sentry.captureException(errors);
      return;
    }
    setDonationStatus(DonationStatus.DONE);
    if (donationCredit) {
      setDonationCredit({
        ...donationCredit,
        donation: data,
      });
    }
    if (shopifyExperienceData) {
      setShopifyExperienceData({
        ...shopifyExperienceData,
        donationGid: data.gid,
      });
    }
    window.parent.postMessage(
      {
        donationComplete: true,
      },
      '*',
    );
  };

  const handleSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmailInput(event.target.value);

  const onCloseModal = () => onToggleModal();

  const cartDonationText = formattedDonationCreditAmountFromCart
    ? ` (${formattedDonationCreditAmountFromPromotion} promotional incentive, ${formattedDonationCreditAmountFromCart} added to cart)`
    : '';

  return (
    <Container>
      <DonationForm>
        <ContentContainer>
          <HeaderDiv>
            {donationStatus === 'DONE'
              ? 'Donation confirmed'
              : 'Confirm your donation'}
          </HeaderDiv>
          {organization?.name && <Divider pixels={10}></Divider>}
          {organization?.name && (
            <TextDiv>
              On your behalf, {donorName} is donating{' '}
              {formattedDonationCreditAmount || 'a portion of proceeds'}
              {cartDonationText} in support of {organization?.name}.
            </TextDiv>
          )}
          <Separator />
          <OrganizationCard>
            <OrganizationImageContainer>
              <OrganizationImage
                height={'100%'}
                width={'100%'}
                src={organization?.logoUrl || ''}
              ></OrganizationImage>
            </OrganizationImageContainer>
            <OrganizationDescriptionContainer>
              <OrganizationName>
                {organization?.name || <Skeleton />}
              </OrganizationName>
              {organization?.name && (
                <OrganizationMetadata organization={organization} />
              )}
              {!organization?.name && (
                <OrganizationMetadataContainer>
                  <Skeleton />
                </OrganizationMetadataContainer>
              )}
              <OrganizationDescription
                description={organization?.description}
                name={organization?.name}
              />
            </OrganizationDescriptionContainer>
          </OrganizationCard>
          {showEmailInput && (
            <InputContainer>
              <Input
                defaultValue={consumerEmail ?? ''}
                onChange={handleSearchOnChange}
                placeholder="Email address"
              ></Input>
            </InputContainer>
          )}
          {donationStatus !== DonationStatus.DONE && (
            <ActionButton
              onClick={() => !isInPreviewMode && donate()}
              loading={donationStatus === DonationStatus.LOADING}
              disabled={isInPreviewMode}
            >
              <DonateCompleteText>Donate Now</DonateCompleteText>
            </ActionButton>
          )}

          {donationStatus === DonationStatus.DONE && organization?.name && (
            <ActionButton disabled hasGreenBackground>
              <DonateCompleteIcon src={checkmarkIcon}></DonateCompleteIcon>
              <DonateCompleteText>Your donation is complete</DonateCompleteText>
            </ActionButton>
          )}
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <Separator />
          {donationStatus !== DonationStatus.DONE && (
            <Disclaimer>
              By clicking "Donate", I agree to create an account with Givz and
              consent to the{' '}
              <DisclaimerLink
                href="https://givz.com/user-agreement"
                target="_blank"
              >
                User Agreement
              </DisclaimerLink>
              .
            </Disclaimer>
          )}
          {donationStatus === DonationStatus.DONE && organization?.name && (
            <>
              <CompleteText>Thank you. You made an impact!</CompleteText>
              <CompleteSubText>
                Organizations typically receive donations in 30-90 days.
              </CompleteSubText>
              {socialSharingEnabled && (
                <SocialSharingButtons
                  amount={experienceData?.socialSharingAmount}
                />
              )}
            </>
          )}
          {donationStatus === DonationStatus.PENDING && (
            <ModalBackButton onClick={onCloseModal} />
          )}
        </ContentContainer>
      </DonationForm>
    </Container>
  );
};
