import { FunctionComponent, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import Skeleton from 'react-loading-skeleton';
import { TextElementCss } from '@givz/core-client';
import { DonationForm } from '../donation-form';
import { Header } from '../header';
import {
  useFetchDonationCredit,
  useGetGivzQueryParamsFromUrl,
  useTrackDevice,
} from '../../hooks';
import {
  errorState,
  experienceDataSelector,
  isInPreviewModeSelector,
} from '../../states';
import { errorMessageSelector, ErrorType } from '../../states/error';
import { PreviewModeBanner } from '../common';
import { isPreviewBannerClosedState } from '../../states/app';
import { CenteringContainer, HeaderText } from '../common/layout';
import Error from '../error';
import 'react-loading-skeleton/dist/skeleton.css';

const defaultPageBackgroundColor = '#f9f5ea';

const HeaderContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100px;
  justify-content: space-between;
  margin: 0 0 15px;
  max-width: 1200px;
  width: 100%;

  @media (max-width: 768px) {
    height: auto;
  }
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 24px;
  height: 100%;
  justify-content: flex-end;
  min-width: 100px;
  text-align: right;
  width: 25%;

  @media (max-width: 768px) {
    height: 80px;
    justify-content: flex-start;
    margin: 0 0 10px;
    width: 40%;
  }
`;

const Logo = styled.img`
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
`;

const Divider = styled.div`
  background-color: rgb(231, 230, 228);
  box-sizing: border-box;
  height: 1px;
  width: calc(100% + 50px);

  @media (max-width: 768px) {
    width: calc(100% + 30px);
  }
`;

const ContentContainer = styled.div<{ widgetOnly: boolean }>`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  justify-content: ${({ widgetOnly }) =>
    widgetOnly ? 'center' : 'space-between'};
  margin: ${({ widgetOnly }) => (widgetOnly ? '0' : '25px 0 0')};
  max-width: ${({ widgetOnly }) => (widgetOnly ? '800px' : '1200px')};
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
    margin: ${({ widgetOnly }) => (widgetOnly ? '0' : '30px 0 0')};
    max-width: 800px;
  }
`;

const DonationDetailsColumn = styled.div`
  box-sizing: border-box;
  width: 47%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const BannerContainer = styled.div`
  aspect-ratio: 16/9;
  background-color: rgb(230, 227, 218);
  border: 1px solid rgb(226, 226, 226);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  margin: 0 0 35px;
  overflow: hidden;
  width: 100%;
`;

const Banner = styled.img`
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  width: auto;
`;

const CampaignBodyText = styled.div<{
  css?: TextElementCss;
}>`
  font-size: 16px;
  line-height: 24px;
  white-space: pre-wrap;

  @media (max-width: 1024px) {
    margin: 0 0 35px;
  }

  // TODO: find a way to refactor this to only one place and build overrides
  font: ${({ css }) => (css?.font ? css.font : undefined)};
  margin: ${({ css }) => (css?.margin ? css.margin : undefined)};
  padding: ${({ css }) => (css?.padding ? css.padding : undefined)};
`;

const DonationFormColumn = styled.div<{ widgetOnly: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${({ widgetOnly }) => (widgetOnly ? '100%' : '47%')};

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const DonationFormContainer = styled.div`
  background-color: #fff;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
`;

const MainHeader: FunctionComponent<{
  logo?: string;
  textCSS?: TextElementCss;
  textValue?: string;
}> = ({ logo, textCSS, textValue }) => (
  <>
    <HeaderContainer>
      <HeaderText css={textCSS}>{textValue || <Skeleton />}</HeaderText>
      {logo && (
        <LogoContainer>
          <Logo src={logo}></Logo>
        </LogoContainer>
      )}
    </HeaderContainer>
    <Divider></Divider>
  </>
);

export const HostedCampaign = () => {
  useGetGivzQueryParamsFromUrl();
  useTrackDevice();
  useFetchDonationCredit();
  const experienceData = useRecoilValue(experienceDataSelector);
  const appError = useRecoilValue(errorState);
  const appErrorMessage = useRecoilValue(errorMessageSelector);
  const isInPreviewMode = useRecoilValue(isInPreviewModeSelector);
  const isPreviewBannerClosed = useRecoilValue(isPreviewBannerClosedState);

  const donationFormRef = useRef<HTMLDivElement>(null);

  // const handleScrollToDonationForm = () => {
  //   if (donationFormRef && donationFormRef.current) {
  //     donationFormRef.current.scrollIntoView({
  //       behavior: 'smooth',
  //     });
  //   }
  // };

  useEffect(() => {
    const pageBackgroundColor =
      experienceData?.landingPage?.backgroundColor ??
      defaultPageBackgroundColor;

    document.body.style.backgroundColor = pageBackgroundColor;
  }, [experienceData?.landingPage?.backgroundColor]);

  useEffect(() => {
    document.title = experienceData?.landingPage?.title.value
      ? `${experienceData?.landingPage?.title.value} | Powered by Givz`
      : 'Givz Donation';
  }, [experienceData?.landingPage?.title.value]);

  const widgetOnly = Boolean(experienceData?.landingPage?.widgetOnly);

  const showHeader =
    !widgetOnly &&
    (experienceData?.landingPage?.title.value ||
      experienceData?.landingPage?.logo.value);

  if (appErrorMessage && appError !== ErrorType.DONATION_CREDIT_EXPIRED) {
    return <Error appError={appError} appErrorMessage={appErrorMessage} />;
  }

  return (
    <>
      {isInPreviewMode && <PreviewModeBanner isInHostedMode={!widgetOnly} />}
      <CenteringContainer
        isPreviewBannerVisible={!isPreviewBannerClosed && isInPreviewMode}
        widgetOnly={widgetOnly}
      >
        {showHeader && (
          <MainHeader
            logo={experienceData.landingPage?.logo.value}
            textCSS={experienceData?.landingPage?.title.css}
            textValue={experienceData?.landingPage?.title.value}
          />
        )}
        <ContentContainer widgetOnly={widgetOnly}>
          {!widgetOnly && (
            <DonationDetailsColumn>
              <BannerContainer>
                <Banner src={experienceData?.landingPage?.hero.value}></Banner>
              </BannerContainer>
              <CampaignBodyText
                css={experienceData?.landingPage?.description.css}
              >
                {experienceData?.landingPage?.description.value && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: experienceData.landingPage.description.value,
                    }}
                  />
                )}
                {!experienceData?.landingPage?.description.value && (
                  <Skeleton />
                )}
              </CampaignBodyText>
            </DonationDetailsColumn>
          )}
          <DonationFormColumn widgetOnly={widgetOnly}>
            <DonationFormContainer ref={donationFormRef}>
              <Header
                amount={experienceData?.formattedDonationCreditAmount}
                bottomBorder={false}
                isInHostedMode={true}
              ></Header>
              <DonationForm />
            </DonationFormContainer>
          </DonationFormColumn>
        </ContentContainer>
      </CenteringContainer>
    </>
  );
};
