import { TextElementCss } from '@givz/core-client';
import styled from 'styled-components';

export const CenteringContainer = styled.div<{
  isPreviewBannerVisible?: boolean;
  widgetOnly?: boolean;
}>`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  justify-content: center;
  padding: ${({ widgetOnly }) => (widgetOnly ? '0' : '10px 25px 50px')};
  padding-top: ${({ isPreviewBannerVisible, widgetOnly }) =>
    widgetOnly ? '0' : isPreviewBannerVisible ? '55px' : '10px'};
  width: 100%;

  @media (max-width: 768px) {
    padding: ${({ widgetOnly }) => (widgetOnly ? '0' : '10px 15px 50px')};
  }
`;

export const HeaderText = styled.div<{
  css?: TextElementCss;
}>`
  font-size: 28px;
  font-weight: 500;
  margin: 0 15px 0 0;
  width: 75%;

  @media (max-width: 768px) {
    font-size: 22px;
    margin: 0;
    width: 60%;
  }

  // TODO: find a way to refactor this to only one place and build overrides
  font: ${({ css }) => (css?.font ? css.font : undefined)};
  margin: ${({ css }) => (css?.margin ? css.margin : undefined)};
  padding: ${({ css }) => (css?.padding ? css.padding : undefined)};
`;
