import {
  DonationWidgetSettingsDto,
  HostedPageSettingsDto,
} from '@givz/core-client';
import { atom, selector } from 'recoil';
import { DeepNullablePartial } from '../../utils';
import { SocialShareNetworks } from '../../states/modal';

type GivzOverrides = {
  amount?: number;
  donationWidgetSettings: DeepNullablePartial<DonationWidgetSettingsDto>;
  hmac?: string;
  hostedPageSettings: DeepNullablePartial<HostedPageSettingsDto>;
  preview: boolean;
  reset?: boolean;
  socialSharing: {
    modalOpen: boolean;
    network?: SocialShareNetworks;
  };
};

export const isEmbeddedState = atom<boolean>({
  default: false,
  key: 'isEmbeddedState',
});

export const isInModalState = atom<boolean>({
  default: false,
  key: 'isInModalState',
});

export const isPreviewBannerClosedState = atom<boolean>({
  default: false,
  key: 'isPreviewBannerClosedState',
});

export const givzQueryParamsState = atom<GivzOverrides | undefined>({
  default: undefined,
  key: 'givzQueryParamsState',
});

export const isInPreviewModeSelector = selector<boolean | undefined>({
  get: ({ get }) => {
    const givzQueryParams = get(givzQueryParamsState);

    return givzQueryParams?.preview;
  },
  key: 'isInPreviewModeSelector',
});
