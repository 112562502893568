import { atom, selector } from 'recoil';
import { DefaultTheme } from 'styled-components';
import theme from '../../styles/theme';
import { experienceDataSelector } from '../experience';

export const themeState = atom<DefaultTheme>({
  default: theme,
  key: 'themeState',
});

export const themeStateSelector = selector<DefaultTheme>({
  get: ({ get }) => {
    const experienceData = get(experienceDataSelector);

    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: {
          ...theme.colors.primary,
          hex: experienceData?.primaryColor ?? theme.colors.primary.hex,
        },
      },
    };
  },
  key: 'themeStateSelector',
});
